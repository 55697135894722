interface Props {
    width?: number,
    height?: number,
    color: string
}

export default function Car({width = 22, height = 22, color}: Props) {
    return <svg width={width} height={height} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.1 8.40003H21.45L17.0308 2.87584C16.7872 2.57139 16.4782 2.32562 16.1267 2.15671C15.7753 1.9878 15.3903 1.90007 15.0004 1.90002H6.31028C5.24712 1.90002 4.29122 2.54718 3.89634 3.53437L1.95 8.49184C0.830375 8.7819 0 9.78981 0 11V15.55C0 15.9092 0.290875 16.2 0.65 16.2H2.6C2.6 18.354 4.34606 20.1 6.5 20.1C8.65394 20.1 10.4 18.354 10.4 16.2H15.6C15.6 18.354 17.3461 20.1 19.5 20.1C21.6539 20.1 23.4 18.354 23.4 16.2H25.35C25.7091 16.2 26 15.9092 26 15.55V12.3C26 10.1461 24.2539 8.40003 22.1 8.40003ZM6.5 18.15C5.42466 18.15 4.55 17.2754 4.55 16.2C4.55 15.1247 5.42466 14.25 6.5 14.25C7.57534 14.25 8.45 15.1247 8.45 16.2C8.45 17.2754 7.57534 18.15 6.5 18.15ZM9.425 8.40003H4.75028L6.31028 4.50003H9.425V8.40003ZM11.375 8.40003V4.50003H15.0004L18.1204 8.40003H11.375ZM19.5 18.15C18.4247 18.15 17.55 17.2754 17.55 16.2C17.55 15.1247 18.4247 14.25 19.5 14.25C20.5753 14.25 21.45 15.1247 21.45 16.2C21.45 17.2754 20.5753 18.15 19.5 18.15Z"
            fill={color}/>
    </svg>;
}