import { Chip, Skeleton } from '@mui/material';
import {GroupStatus} from "../../actions/group.type";

interface Props {
    status?: GroupStatus;
    variant?: 'chip' | 'text';
}

const chips: { [key in GroupStatus]: JSX.Element } = {
    [GroupStatus.New]: <Chip label="Идёт набор"/>,
    [GroupStatus.Active]: <Chip label="Идёт обучение" color="primary"/>,
    [GroupStatus.Exams]: <Chip label="Экзамены" color="warning"/>,
    [GroupStatus.Finished]: <Chip label="Завершена" color="success"/>
};

const texts: { [key in GroupStatus]: JSX.Element } = {
    [GroupStatus.New]: <>Идёт набор</>,
    [GroupStatus.Active]: <>Идёт обучение</>,
    [GroupStatus.Exams]: <>Экзамены</>,
    [GroupStatus.Finished]: <>Завершена</>
};

export function GroupStatusChip({status, variant = 'chip'}: Props) {
    if (variant === 'text') {
        if (!status) return <Skeleton width={150} height={35}/>;
        return texts[status];
    } else {
        if (!status) return <Skeleton width={100} height={30}/>;
        return chips[status];
    }
}