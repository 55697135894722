import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {blue} from "@mui/material/colors";
import { attachTransportToGroup, detachTransportFromGroup } from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import ClearIcon from "@mui/icons-material/Clear";
import {getSchoolTransport} from "../../../Transport/actions/api";
import {DirectionsCar} from "@mui/icons-material";
import { useUserStore } from '../../../global/store/UserStore';

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TransportWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface TransportModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const TransportModal = ({groupInfo, fetchGroupInfo, open, onClose} : TransportModalProps) => {

    const [transportList, setTransportList] = useState<any[]>([]);
    const [transportId, setTransportId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getSchoolTransport(Number(schoolInfo.id))
            .then((res) => {
                setTransportList(res.data);
            })
    // eslint-disable-next-line
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTransportId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !transportId) {
            snackbar.errorMessage('Необходимо выбрать транспорт');
            return;
        }

        const transportDto = {
            group_id: groupInfo.id,
            car_id: Number(transportId)
        }

        attachTransportToGroup(transportDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Прикрепить транспортное средство к группе <i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={transportId}
                    >
                        {transportList.map((transport) => {
                            return (
                                <MenuItem value={transport.id} key={transport.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            <DirectionsCar />
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={1}
                                                   sx={{width: '100%'}}>
                                                <Typography>{transport.model}</Typography>
                                                <Typography variant="body2" color="gray">{transport.plate_number}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {'Примечание: в списке транспортных средств отображаются только те транспортные средства, которые прикреплены к этому учебной организации как ТС. Чтобы добавить новое ТС, сперва прикрепите его к организации'}
                    </Typography>

                    <Button onClick={handleAttach}>
                        Прикрепить ТС
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const TransportWindow = ({groupInfo, fetchGroupInfo} : TransportWindowProps) => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        console.log(`GroupInfo Transport`)
        console.log(groupInfo)
    }, [])

    const handleDetach = () => {
        const transportDto = {
            car_id: groupInfo.transport.car.id,
            group_id: groupInfo.id
        }

        detachTransportFromGroup(transportDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }


    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>
                <Typography variant="h4">Транспортное средство</Typography>
                {(groupInfo.transport.length === 0 || !groupInfo.transport) && (
                    <>
                        <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                            Необходимо прикрепить транспортное средство к данной группе
                        </Typography>
                        <Button onClick={() => {setIsDialogOpen(true);}}>
                            Выбрать ТС
                        </Button>
                    </>
                )}
                {groupInfo.transport.length > 0 && (
                    groupInfo.transport.map((transport: any) => {
                        return (
                          <>
                              <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                                  Транспортные средства в данной группе:
                              </Typography>
                              <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                                  <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                  >
                                      <DirectionsCar />
                                  </Avatar>
                                  <Stack sx={{width: '100%'}}>
                                      <Stack sx={{width: '100%'}}>
                                          <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={1}
                                                 sx={{width: '100%'}}>
                                              <Typography>{transport.car.model}</Typography>
                                              <Typography variant="body2" color="gray">{transport.car.plate_number}</Typography>
                                          </Stack>
                                      </Stack>
                                  </Stack>
                                  {groupInfo.status === 1 && (<Tooltip title={'Открепить'}>
                                      <ClearIcon
                                        sx={[
                                            {ml: 5},
                                            {
                                                '&:hover': {
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                },
                                            },
                                        ]}
                                        onClick={handleDetach}
                                      />
                                  </Tooltip>)}
                              </Stack>
                              <Button onClick={() => {setIsDialogOpen(true);}}>
                                  Добавить ТС
                              </Button>
                          </>
                        )
                    })
                )}
            </Paper>

            <TransportModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default TransportWindow;