import React, { useEffect, useState } from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
  Alert,
  Avatar,
  Box, Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Stack, TextField,
  Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {useSchoolStore} from "../store/SchoolStore";
import {useUserStore} from "../../global/store/UserStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import { getKursantInfo, submitKursantDocsInfo } from '../actions/api';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { Person } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import LoadingButton from '../../global/components/LoadingButton';
import ActionButton from '../../Landing/components/ActionButton';
import { formatDate } from '../../global/helpers/text';

export interface DocsDto {
  date_of_birth: Date | null,
  polyclinic: string | null,
  doc_number: string | null,
  identification_number: string | null,
  identification_date_of_issue: Date | null,
  price: string | null,
  study_period: number | null,
  initial_number: string | null,
}

const SchoolKursants = () => {

  const {id, kursantId} = useParams();
  const navigate = useNavigate();

  const schoolInfo = useSchoolStore((state) => state);
  const isAdmin = useUserStore((state) => state.isAdmin);
  const snackbar = useSnackbarStore((state) => state);

  const [kursantInfo, kursantInfoLoading, , fetchKursantInfo] = useLoadingHook(getKursantInfo);

  const [docsInfo, setDocsInfo] = useState<DocsDto>({
    date_of_birth: null,
    polyclinic: null,
    doc_number: null,
    identification_number: null,
    identification_date_of_issue: null,
    price: null,
    study_period: null,
    initial_number: null,
  });
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchKursantInfo(Number(kursantId), Number(id));
  }, []);

  if (!kursantInfo) {
    return (
      <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <PageHeader/>

        <Box sx={{...wrapperSx}}>
          <Stack sx={{p: 5, justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </Stack>
        </Box>
      </Box>
    )
  };

  const handleDocsInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setDocsInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  };

  const handleSubmit = () => {

    if (!docsInfo.date_of_birth ||
        !docsInfo.polyclinic ||
        !docsInfo.doc_number ||
        !docsInfo.identification_number ||
        !docsInfo.identification_date_of_issue ||
        !docsInfo.price ||
        !docsInfo.study_period ||
        !docsInfo.initial_number) {
      snackbar.errorMessage('Необходимо заполнить все дополнительные данные')
      return;
    }

    setSubmitLoading(true);

    submitKursantDocsInfo(Number(kursantInfo.id), docsInfo)
      .then(() => {
        setInfoDialogOpen(false);
        fetchKursantInfo(Number(kursantId), Number(id));
      })
      .finally(() => {
        setSubmitLoading(false);
      })
  };

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} sx={{mb: 2}}>
          <Typography sx={{mt: 3}} variant={'h5'}><b>Курсантыучебной организации (студенты)</b></Typography>
          <Typography variant="subtitle1">
            Здесь отображается информация о курсанте <i><b>{kursantInfo.last_name} {kursantInfo.first_name}</b></i>
          </Typography>
        </Stack>

        {!kursantInfo.exam_card && <Alert severity={'error'}>
          Необходимо внести дополнительные данные о курсанте для генерации дополнительной документации (Экзаменационная карточка, Результаты экзаменов, Договор об обучении, Подтверждение обучения)
        </Alert>}

        {kursantInfo.exam_card && <Alert severity={'success'}>
            Дополнительные данные о курсанте заполнены. У вас есть доступ к дополнительной документации (Экзаменационная карточка, Результаты экзаменов, Договор об обучении, Подтверждение обучения)
        </Alert>}

        <Stack flexDirection={'row'} gap={10}>
          <Stack flexDirection={'column'} sx={{py: 2}}>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <Person />
              </Avatar>
              <Typography>
                <b>Ф.И.О:</b>
              </Typography>
              <Typography>
                {kursantInfo.last_name} {kursantInfo.first_name} {kursantInfo.patronymic}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <Person />
              </Avatar>
              <Typography>
                <b>ИИН:</b>
              </Typography>
              <Typography>
                {kursantInfo.iin}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <Person />
              </Avatar>
              <Typography>
                <b>Дата рождения:</b>
              </Typography>
              <Typography>
                {kursantInfo.exam_card ? formatDate(kursantInfo.exam_card.date_of_birth) : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <Person />
              </Avatar>
              <Typography>
                <b>Номер удостоверения личности</b>
              </Typography>
              <Typography>
                {kursantInfo.kursant_contract ? kursantInfo.kursant_contract.identification_number : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <SchoolIcon />
              </Avatar>
              <Typography>
                <b>Учебная группа:</b>
              </Typography>
              <Typography>
                {kursantInfo.group.title}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <SchoolIcon />
              </Avatar>
              <Typography>
                <b>Дата начала обучения:</b>
              </Typography>
              <Typography>
                {kursantInfo.group.start_date ? formatDate(new Date(kursantInfo.group.start_date)) : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <SchoolIcon />
              </Avatar>
              <Typography>
                <b>Дата окончания обучения:</b>
              </Typography>
              <Typography>
                {kursantInfo.group.end_date ? formatDate(new Date(kursantInfo.group.end_date)) : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Avatar
                sx={{fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'}}
              >
                <SchoolIcon />
              </Avatar>
              <Typography>
                <b>Категория обучения:</b>
              </Typography>
              <Typography>
                {kursantInfo.group.category_id}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={'column'} sx={{py: 2}}>
            <ActionButton
              title={'Экзаменационная карточка водителя'}
              width={'250px'}
              height={'150px'}
              onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/exam-card`)}}
            />
            <ActionButton
              title={'Результаты экзаменов'}
              width={'250px'}
              height={'150px'}
              onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/results`)}}

            />
          </Stack>
          <Stack flexDirection={'column'} sx={{py: 2}}>
            <ActionButton
              title={'Договор'}
              width={'250px'}
              height={'150px'}
              onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/kursant-contract`)}}
            />
            <ActionButton
              title={'Подтверждение обучения'}
              width={'250px'}
              height={'150px'}
              onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/studying-confirmation`)}}
            />
          </Stack>
        </Stack>
        {!kursantInfo.exam_card && (
          <Button
            variant={'contained'}
            onClick={() => setInfoDialogOpen(true)}
          >
            Внести дополнительные данные Курсанта
          </Button>
        )}

        <Dialog open={infoDialogOpen} fullWidth>
          <DialogTitle>
            Внесение дополнительных данных о курсанте {kursantInfo.last_name} {kursantInfo.first_name}
          </DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Stack gap={2}>
                <Typography variant={'h6'}>
                  Данные для экзаменационной карточки
                </Typography>
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.date_of_birth}
                  InputLabelProps={{ shrink: true }}
                  name={'date_of_birth'}
                  type={'date'}
                  label={'Дата рождения'}
                />
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.polyclinic}
                  InputLabelProps={{ shrink: true }}
                  name={'polyclinic'}
                  type={'text'}
                  label={'Название мед.центра'}
                />
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.doc_number}
                  InputLabelProps={{ shrink: true }}
                  name={'doc_number'}
                  type={'text'}
                  label={'Номер документа'}
                />
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h6'}>
                  Данные для договора
                </Typography>
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.identification_number}
                  InputLabelProps={{ shrink: true }}
                  name={'identification_number'}
                  type={'text'}
                  label={'Номер удостоверения личности'}
                />
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.identification_date_of_issue}
                  InputLabelProps={{ shrink: true }}
                  name={'identification_date_of_issue'}
                  type={'date'}
                  label={'Дата выдачи удостоверения личности'}
                />
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.price}
                  InputLabelProps={{ shrink: true }}
                  name={'price'}
                  type={'text'}
                  label={'Сумма договора'}
                />
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.study_period}
                  InputLabelProps={{ shrink: true }}
                  name={'study_period'}
                  type={'number'}
                  label={'Период обучения (в днях)'}
                />
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h6'}>
                  Данные для подтверждения об обучении
                </Typography>
                <TextField
                  onChange={handleDocsInput}
                  value={docsInfo.initial_number}
                  InputLabelProps={{ shrink: true }}
                  name={'initial_number'}
                  type={'text'}
                  label={'Номер документа'}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant={'contained'}
              color={'error'}
              onClick={() => setInfoDialogOpen(false)}
              loading={submitLoading}
            >
              Отмена
            </LoadingButton>

            <LoadingButton
              variant={'contained'}
              color={'primary'}
              onClick={() => handleSubmit()}
              loading={submitLoading}
            >
              Сохранить данные
            </LoadingButton>
          </DialogActions>
        </Dialog>

      </Box>
    </Box>
  );
};

export default SchoolKursants;