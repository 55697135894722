import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {Avatar, Box, Chip, Stack, Tab, Tabs, Typography} from "@mui/material";
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import {getAllDrivingAppointmentsBySchoolId, getDrivingEmployeesWithClasses} from '../actions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {formatDate, getName} from '../../global/helpers/text';
import NoRows from '../../global/components/NoRows';
import MiniSchedule from '../components/MiniSchedule';
import {AppointmentStatus} from "../../Kursant/components/KursantDrivingGrid";
import DrivingServicesList from "../components/DrivingServicesList";

type Tabs = 1 | 2 | 3 | 4;

const DrivingAppointments = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [appointmentsList, appointmentsLoading, , fetchAppointments] = useLoadingHook(getAllDrivingAppointmentsBySchoolId);
    const [selectedTab, setSelectedTab] = useState<Tabs>(1);


    useEffect(() => {
        fetchAppointments(Number(id), selectedTab);
    }, []);

    useEffect(() => {
        fetchAppointments(Number(id), selectedTab)
    }, [selectedTab]);

    const tabs = {
        created: {id: 1, label: `Новые`},
        approved: {id: 2, label: `Подтвержденные`},
        denied: {id: 3, label: `Отклоненные`},
        canceled: {id: 4, label: `Отмененные`}
    };

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 50, sortable: true},
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'phone', headerName: 'Номер телефона',
            width: 160,
        },
        {
            field: 'locality', headerName: 'Город/НП',
            width: 250,
            renderCell: ({row}) => `${row.school.region}, ${row.school.locality}`
        },
        {
            field: 'date', headerName: 'Дата записи',
            width: 120,
            renderCell: ({row}) => formatDate(row.date)
        },
        {
            field: 'hour', headerName: 'Время записи',
            width: 120,
            renderCell: ({row}) => row.hour
        },
        {
            field: 'employee.name', headerName: 'ФИО инструктора',
            width: 250,
            renderCell: ({row}) => {
                const name = row.employee.type === 'driver' ? getName(row.employee.driver) : getName(row.employee.master);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'status', headerName: 'Статус записи',
            width: 200,
            renderCell: ({row}) => {
                return <Chip label={AppointmentStatus[row.status]}/>
            }
        },
        {
            field: 'class', headerName: 'Услуга',
            minWidth: 500,
            renderCell: ({row}) => {
                return <DrivingServicesList classes={[row.class]}/>
            }
        },
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Записи на вождение</b></Typography>
                    </Stack>
                    <Typography variant="subtitle1">
                        Здесь отображаются все записи на вождение вашей учебной организации. Для изменения статуса записи - нажмите на нее.
                    </Typography>

                    <Tabs
                        value={selectedTab}
                        onChange={(e, newValue) => setSelectedTab(newValue)}
                        variant="scrollable"
                        scrollButtons={false}
                        sx={{mt: 2, ml: 2}}
                    >
                        {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
                    </Tabs>
                    <DataGrid
                            columns={columns}
                            rows={appointmentsList ? appointmentsList : []}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            loading={appointmentsLoading}
                            autoHeight={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            style={{margin: '0', height: '100%', width: '100%'}}
                            slots={{
                                noRowsOverlay: NoRows
                            }}
                            getRowHeight={() => 'auto'} 
                            getRowClassName={() => 'dataGridRow'}
                            onRowClick={({row}) => {navigate(`/schools/${id}/driving/appointments/${row.id}`)}}
                        />
                </Box>
            </Box>
        </Box>
    );
};

export default DrivingAppointments;