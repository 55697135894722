import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRowModel} from "@mui/x-data-grid";
import {formatDate, getName} from "../../global/helpers/text";
import {Avatar, Button, Chip, Stack, Tab, Tabs} from "@mui/material";
import NoRows from "../../global/components/NoRows";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {approveCorrection, getAllCorrections, getAllCorrectionsInvoices, turnCorrInvoicePaid, turnCorrInvoiceUnpaid} from "../actions/api";
import {Link} from "react-router-dom";
import {DirectionsCar} from "@mui/icons-material";
import LoadingButton from "../../global/components/LoadingButton";

interface ButtonProps {
    row: GridRowModel<any>,
    fetchInvoices: (tab: string) => void,
    selectedTab: string
}

const TurnPaidButton = ({row, fetchInvoices, selectedTab}: ButtonProps) => {
    const [executeInfo, executeLoading, , executeCorrection] = useLoadingHook(turnCorrInvoicePaid);

    useEffect(() => {
        if (executeInfo) {
            fetchInvoices(selectedTab);
        }
    }, [executeInfo])

    return (
        <Stack>
            <LoadingButton
                color={'primary'}
                variant={'contained'}
                onClick={() => executeCorrection(row.id)}
                sx={{height: 'auto'}}
                loading={executeLoading}
            >
                Оплачено
            </LoadingButton>
        </Stack>
    );
}

const TurnUnpaidButton = ({row, fetchInvoices, selectedTab}: ButtonProps) => {
    const [executeInfo, executeLoading, , executeCorrection] = useLoadingHook(turnCorrInvoiceUnpaid);

    useEffect(() => {
        if (executeInfo) {
            fetchInvoices(selectedTab);
        }
    }, [executeInfo])

    return (
        <Stack>
            <LoadingButton
                color={'error'}
                variant={'contained'}
                onClick={() => executeCorrection(row.id)}
                sx={{height: 'auto'}}
                loading={executeLoading}
            >
                Не оплачен
            </LoadingButton>
        </Stack>
    );
}

const AdminCorrectionsInvoicesGrid = () => {

    const [invoicesList, invoicesLoading, , fetchInvoices] = useLoadingHook(getAllCorrectionsInvoices);
    const [selectedTab, setSelectedTab] = useState<string>('false');

    useEffect(() => {
        fetchInvoices(selectedTab)
    }, []);

    useEffect(() => {
        fetchInvoices(selectedTab)
    }, [selectedTab])

    const tabs = {
        created: {id: 'false', label: `Неоплаченные`},
        approved: {id: 'true', label: `Оплаченные`},
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            sortable: true,
        },
        {
            field: 'title', headerName: 'Наименование счета', width: 500, sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    Счет на оплату по услугам корректировки за {row.month} {row.year} г.
                </React.Fragment>;
            }
        },
        {
          field: 'sum', headerName: 'Сумма счета', width: 150, sortable: false,
          renderCell: ({row}) => {
              return <React.Fragment>
                  {row.sum} тг.
              </React.Fragment>;
          }
        },
        {
            field: 'school.title',
            headerName: 'Название учебной организации',
            headerClassName: 'datagrid--header',
            minWidth: 300,
            sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link
                        to={`/schools/${row.school.id}`}
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {row.school.title}
                    </Link>
                </React.Fragment>;
            }
        },
        {
            field: 'created_at',
            headerName: 'Дата',
            width: 150,
            sortable: true,
            renderCell: ({row}) => {
                return formatDate(row.created_at)
            }
        },
        {
            field: 'download', headerName: 'Просмотр счета', width: 300, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Button 
                        href={`/schools/${row.school_id}/corrections-invoices/${row.id}`} 
                        target={"_blank"} rel="noreferrer"
                        sx={{height: 'auto'}}
                    >
                        Перейти к счету
                    </Button>
                )
            }
        },
        {
            field: 'paid', headerName: 'Статус оплаты', width: 200, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Chip label={`${row.paid ? 'Оплачен' : 'Не оплачен'}`} color={`${row.paid ? 'primary' : 'error'}`}/>
                )
            }
        },
        {
            field: 'actions', headerName: 'Действия', width: 150, sortable: false,
            renderCell: ({row}: GridRowModel<any>) => {
                return row.paid ? (<TurnUnpaidButton row={row} fetchInvoices={fetchInvoices} selectedTab={selectedTab}/>) : (<TurnPaidButton row={row} fetchInvoices={fetchInvoices} selectedTab={selectedTab}/>)
            }
        },
        {
            field: 'resolver', headerName: 'Исполнитель', width: 300, sortable: false,
            renderCell: ({row}) => {

                if (!row.resolver) {
                    return 'Отсутствует'
                }

                const name = getName(row.resolver);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {name.fullName}
                </React.Fragment>;
            }
        },
    ];

    return (
        <Stack>
            <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>
            <DataGrid
                columns={columns}
                rows={invoicesList ? invoicesList : []}
                loading={invoicesLoading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Stack>
    );
};

export default AdminCorrectionsInvoicesGrid;