import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    deleteSchoolCascade,
    deleteSchoolDima,
    getSchoolAttributes,
    getSchoolBalance,
    getSchoolInfo
} from '../actions/api';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, List, ListItem,
    Stack,
    SxProps,
    Theme,
    Typography
} from '@mui/material';
import { DirectionsCar, Person, SensorDoor } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {CategoriesGrid} from "../components/CategoriesGrid";
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StorageIcon from '@mui/icons-material/Storage';
import {Card} from "../components/Card";
import {useSchoolStore} from "../store/SchoolStore";
import PageHeader from "../../global/components/PageHeader";
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {formatDate} from "../../global/helpers/text";
import { useUserStore } from '../../global/store/UserStore';
import {wrapperSx} from "../../global/helpers/globalstyles";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const titleSx: SxProps<Theme> = {
    width: 181,
    fontWeight: 500,
    color: '#000',
    fontSize: 14
};

const valueSx: SxProps<Theme> = {
    fontSize: 14
};

interface SchoolAttributesType {
    drivers: {
        total: number,
        active: number,
    },
    masters: {
        total: number,
        active: number,
    },
    teachers: {
        total: number,
        active: number
    },
    groups: {
        total: number,
        active: number,
    },
    transports: {
        total: number,
    },
    base: {
        created_at: string | null,
        updated_at: string | null
    }
}

const SchoolPage = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);
    const [schoolInfo, setSchoolInfo] = useState<any>({
        address: '',
        avatar: null,
        balance: 0,
        bin: '',
        categories: [],
        director: '',
        fts: '',
        id: 0,
        phone1: '',
        phone2: '',
        title: '',
        region: '',
        locality: '',
        user: {
            iin: '',
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            patronymic: ''
        }
    });
    const [schoolAttributes, setSchoolAttributes] = useState<SchoolAttributesType>({
        drivers: {
            total: 0,
            active: 0,
        },
        masters: {
            total: 0,
            active: 0,
        },
        teachers: {
            total: 0,
            active: 0
        },
        groups: {
            total: 0,
            active: 0,
        },
        transports: {
            total: 0,
        },
        base: {
            created_at: null,
            updated_at: null
        }
    });
    const [attributesLoading, setAttributesLoading] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [balance, setBalance] = useState<number>(0);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [schoolInfoLoading, setSchoolInfoLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchSchoolInfo();
    },[]);

    const fetchSchoolInfo = async () => {
        setSchoolInfoLoading(true);
        setAttributesLoading(true);
        try {
            const schoolInfoRes = await getSchoolInfo(Number(id));
            setSchoolInfo(schoolInfoRes.data);
            currentSchool.storeSchoolInfo(schoolInfoRes.data.id, schoolInfoRes.data.title);
            const balanceRes = await getSchoolBalance(Number(id));
            setBalance(balanceRes.data.balance);
            const attributesRes = await getSchoolAttributes(Number(id));
            setSchoolAttributes(attributesRes.data);
        } catch (err: any) {
            snackbar.errorMessage(err.response.data.message);
            navigate('/schools');
        } finally {
            setAttributesLoading(false);
            setSchoolInfoLoading(false);
        }
    };

    useEffect(() => {
        if (attributesLoading) {
            setAlertVisible(false);
        } else {
            if (schoolAttributes.teachers.active === 0 ||
                schoolAttributes.drivers.active === 0 ||
                schoolAttributes.masters.active === 0 ||
                schoolAttributes.transports.total === 0) {
                setAlertVisible(true);
            } else {
                setAlertVisible(false);
            }
        }
    }, [schoolAttributes, attributesLoading])

    const adminDeleteSchool = () => {
        setDeleteLoading(true);
        deleteSchoolCascade(schoolInfo.id)
            .then((res) => {
                navigate('/schools');
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setDeleteLoading(false);
            })
    }

    const deleteSchoolAndUser = () => {
        setDeleteLoading(true);
        deleteSchoolDima(schoolInfo.id)
            .then((res) => {
                navigate('/schools');
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setDeleteLoading(false);
            })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo.title}
                        </Typography>
                    </Stack>

                    {/* TODO: Разблокировать после окончания разработки */}
                    {isAdmin && (<Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}} ><b>Баланс</b></Typography>
                        <Stack flexDirection={'row'} alignItems='center'>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>Текущий баланс - <b>{balance} тг.</b> </Typography>
                            <Button 
                                sx={{ml: 2, height: 'auto'}}
                                variant='outlined'
                                onClick={() => {navigate(`/schools/${schoolInfo.id}/topup`)}}
                            >
                                Пополнить
                            </Button>
                        </Stack>
                    </Box>)}

                    <Stack
                        flexDirection={'row'}
                        gap={'48px'}
                    >
                        <Stack>
                            <section className={'school'}>
                            <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}} ><b>Данные учебной организации</b></Typography>

                            <Stack gap={'16px'}>
                                <Stack flexDirection="row">
                                    <Typography sx={titleSx}><b>БИН/ИИН:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.bin}</Typography>
                                </Stack>
                                <Stack flexDirection="row">
                                    <Typography sx={titleSx}><b>Адрес:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo.region ? `${schoolInfo.region}, ` : ''}{schoolInfo.locality ? `${schoolInfo.locality}, ` : ''}{schoolInfo?.address}</Typography>
                                </Stack>
                                <Stack flexDirection="row">
                                    <Typography sx={titleSx}><b>Ф.И.О. директора:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director}</Typography>
                                </Stack>
                                <Stack flexDirection="row">
                                    <Typography sx={titleSx}><b>Основной телефон:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.phone1}</Typography>
                                </Stack>
                            </Stack>
                            </section>

                            <section className={'user'} style={{marginTop: '32px'}}>
                                <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}} ><b>Данные специалиста</b></Typography>

                                <Stack gap={'16px'}>
                                    <Stack flexDirection="row">
                                        <Typography sx={titleSx}><b>БИН/ИИН:</b></Typography>
                                        <Typography sx={valueSx}>{schoolInfo.user.iin}</Typography>
                                    </Stack>
                                    <Stack flexDirection="row">
                                        <Typography sx={titleSx}><b>Ф.И.О.:</b></Typography>
                                        <Typography sx={valueSx}>{schoolInfo.user.last_name ? `${schoolInfo.user.last_name} ` : ''}{schoolInfo.user.first_name} {schoolInfo?.patronymic}</Typography>
                                    </Stack>
                                    <Stack flexDirection="row">
                                        <Typography sx={titleSx}><b>Электронная почта:</b></Typography>
                                        <Typography sx={valueSx}>{schoolInfo.user.email}</Typography>
                                    </Stack>
                                </Stack>
                            </section>
                        </Stack>

                        <Stack>
                            <CategoriesGrid categories={schoolInfo.categories} />
                        </Stack>
                    </Stack>
                </Stack>



                <Box sx={{mt: 4}}>
                    <Typography variant="h4"  sx={{ml: 1}}>База данных</Typography>
                    {alertVisible && (
                        <Alert severity={'error'} sx={{my: 1}}>
                            Необходимо добавить следующую информацию для полноценного использования системы:
                            <List sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                {!schoolAttributes.base.updated_at && <li><Chip label={'Учебно-материальной базе'}/></li>}
                                {schoolAttributes.teachers.active === 0 && <li><Chip label={'Преподавателям'}/></li>}
                                {schoolAttributes.drivers.active === 0 &&
                                    <li><Chip label={'Мастерам обучения вождению'}/></li>}
                                {schoolAttributes.masters.active === 0 &&
                                    <li><Chip label={'Мастерам производственного обучения'}/></li>}
                                {schoolAttributes.transports.total === 0 &&
                                    <li><Chip label={'Учебным транспортным средствам'}/></li>}
                            </List>
                        </Alert>
                    )}
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>

                        <Card
                          title="Кабинеты"
                          icon={<SensorDoor/>}
                          footer={attributesLoading ? [] : []}
                          onClick={() => {navigate(`/schools/${schoolInfo.id}/cabinets`)}}
                        />

                        <Card
                            title="Преподаватели"
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: 'Всего', value: schoolAttributes.teachers.total},
                                {title: 'Текущих', value: schoolAttributes.teachers.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/teachers`)}}
                        />

                        <Card
                            title="Мастера обучения вождению"
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: 'Всего', value: schoolAttributes.drivers.total},
                                {title: 'Текущих', value: schoolAttributes.drivers.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/drivers`)}}
                        />

                        <Card
                            title="Мастера производственного обучения"
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: 'Всего', value: schoolAttributes.masters.total},
                                {title: 'Текущих', value: schoolAttributes.masters.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/masters`)}}
                        />

                        <Card
                            title="Транспорт"
                            icon={<DirectionsCar/>}
                            footer={attributesLoading ? [] : [
                                {title: 'Всего', value: schoolAttributes.transports.total},
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/transport`)}}
                        />

                        <Card
                            title="Группы"
                            icon={<SchoolIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: 'Всего', value: schoolAttributes.groups.total},
                                {title: 'Текущих', value: schoolAttributes.groups.active}
                            ]}
                            onClick={() => {navigate('/groups')}}
                        />

                        <Card
                          title="Курсанты"
                          icon={<Person/>}
                          footer={[]}
                          onClick={() => {navigate(`/schools/${schoolInfo.id}/kursants`)}}
                        />

                        <Card
                            title="Сотрудники (специалисты)"
                            icon={<Person/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/workers`)}}
                        />

                        {/*<Card*/}
                        {/*    title="Запись на вождение"*/}
                        {/*    icon={<DirectionsCar/>}*/}
                        {/*    footer={[]}*/}
                        {/*    onClick={() => {navigate(`/schools/${schoolInfo.id}/driving`)}}*/}
                        {/*/>*/}
                    </Stack>
                </Box>

                {/* TODO: Разблокировать для юзеров после окончания разработки */}
                {isAdmin && (<Box>
                    <Typography variant="h4" sx={{ml: 1}}>Модули в разработке</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>
                        
                        <Card
                            title="Транзакции"
                            icon={<AttachMoneyIcon/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/transactions`)}}
                        />

                        <Card
                            title="Запись на вождение"
                            icon={<DirectionsCar/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/driving`)}}
                        />
                    </Stack>
                </Box>)}

                {isAdmin && (<Box sx={{mt: 4}}>
                    <Typography variant="h4">Изменение данных школы (видно только администраторам)</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>
                        <Button
                            variant={'contained'}
                            color={'warning'}
                            sx={{height: '50px'}}
                            onClick={() => {navigate(`/schools/${id}/change-by-admin`)}}
                        >
                            Изменить школу
                        </Button>
                    </Stack>
                </Box>)}

                {isAdmin && (<Box sx={{mt: 4}}>
                    <Typography variant="h4">Удаление школы (видно только администраторам)</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>
                        <Button
                            variant={'contained'}
                            color={'error'}
                            sx={{height: '50px'}}
                            onClick={() => {setDeleteDialogOpen(true)}}
                        >
                            Удалить школу
                        </Button>
                    </Stack>
                </Box>)}

                {isAdmin && currentUser.iin === '970812350705' && (<Box sx={{mt: 4}}>
                    <Typography variant="h4">Удаление школы и юзера без заявки (видно только Диме)</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>
                        <Button
                            variant={'contained'}
                            color={'error'}
                            sx={{height: '50px'}}
                            onClick={() => {deleteSchoolAndUser()}}
                        >
                            Удалить школу
                        </Button>
                    </Stack>
                </Box>)}

                <Dialog
                    open={deleteDialogOpen && isAdmin}
                    maxWidth={'xl'}
                >
                    <DialogTitle>
                        Удаление школы <b>{schoolInfo.title}</b>
                    </DialogTitle>
                    <DialogContent>

                        <Typography><i><b>Внимание!</b></i></Typography>
                        <Typography sx={{mt: 1}}><i>Если у прикрепленного к школе пользователя есть еще действующие школы - <b>пользователь не удалится</b>.</i></Typography>
                        <Typography sx={{mt: 1}}><i>Если данная школа является единственной прикрепленной к пользователю - <b>удалится и пользователь</b>.</i></Typography>

                        <Stack sx={{mt: 3}} flexDirection={'row'} gap={2} justifyContent={'space-between'}>
                            <Button
                                variant={'outlined'}
                                onClick={() => setDeleteDialogOpen(false)}
                                disabled={deleteLoading}
                            >
                                Отмена
                            </Button>

                            {!deleteLoading && (<Button
                                variant={'contained'}
                                color={'error'}
                                onClick={() => {adminDeleteSchool();}}
                                disabled={deleteLoading}
                            >
                                Подтвердить удаление
                            </Button>)}

                            {deleteLoading && (<CircularProgress color={'error'}/>)}
                        </Stack>
                    </DialogContent>
                </Dialog>

                <Dialog
                  maxWidth={'md'}
                  fullWidth={true}
                  open={schoolInfoLoading}
                >
                    <DialogTitle>
                        Загрузка данных из АИС Автошкола
                    </DialogTitle>
                    <DialogContent>
                        <Stack flexDirection={'column'} gap={2}>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <Typography>Производится получение данных по учебной организации</Typography>
                            </Stack>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <CircularProgress />
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default SchoolPage;
