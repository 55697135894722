import {Chip, ChipProps, Menu, MenuItem, PaperProps, SxProps, Theme} from '@mui/material';
import React, {Fragment, MouseEvent, useEffect, useId, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TestCategoriesSearchDto} from '../../actions/testCategory.type';
import {getCategories} from "../../actions/api";
import useLoadingHook from "../../../global/hooks/UseLoadingHook";

const PaperProp: Partial<PaperProps> = {
    elevation: 0,
    style: {
        maxHeight: 48 * 9.5 + 8
    },
    sx: {
        boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -15px 15px 40px -4px',
        mt: 1.5
    }
};

interface Props {
    categoryId?: number;
    label?: string;
    sx?: SxProps<Theme>,
    onChange?: (newValue: number) => void;
    fullWidth?: boolean;
    search?: false;
}

interface SearchProps {
    search: true;
    categoryId: number;
    label?: string;
    sx?: SxProps<Theme>,
    onChange?: (newValue: number) => void;
    fullWidth?: boolean;
}

export default function QuestionCategorySelect(
    {
        search = false,
        categoryId,
        label = 'Категория вопроса',
        sx = {},
        onChange,
        fullWidth = true,
    }: Props | SearchProps
) {

    const id = useId();
    const [data, categoriesLoading, categoriesError, fetchCategories] = useLoadingHook(getCategories);
    useEffect(() => {
        fetchCategories()
    }, []);
    const [value, setValue] = useState<number>(categoryId || 0);

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);

    const openMenu = (event: MouseEvent<HTMLElement>) => setAnchor(event.currentTarget);
    const closeMenu = () => setAnchor(null);

    const categories: TestCategoriesSearchDto = {
        items: [
            ...(search) ? [{id: -1, title: 'Все категории'}] : [],
            ...(data?.items || [])
        ]
    };

    useEffect(() => {
        if (categoryId && categoryId !== value) setValue(categoryId);
    }, [categoryId]);

    // @ts-ignore
    const color: ChipProps['color'] = {
        0: 'warning',
        1000: 'warning',
        1001: 'success'
    }[value || 0] || 'default';

    const item = categories?.items?.filter(i => i.id === (value || 0))[0];

    // if (!item) return <Skeleton height={40} width={300}/>;

    return <Fragment>
        <Chip
            label={(item ? (item.id < 0 ? item.title : item.id ? `${item.id}. ${item.title}` : '⚠️ Без категории') : '️⚠️ Без категории') + ' | Казахстан'}
            deleteIcon={<ExpandMoreIcon/>}
            onDelete={openMenu}
            onClick={openMenu}
            color={color}
        />
        <Menu
            anchorEl={anchor}
            open={open}
            transformOrigin={{horizontal: 'center', vertical: 'top'}}
            anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
            onClose={closeMenu}
            // onClick={closeMenu}
            sx={{boxShadow: 0}}
            PaperProps={PaperProp}
        >
            {categories?.items.map(category =>
                <MenuItem key={category.id} value={category.id} onClick={() => {
                    setValue(category.id);
                    onChange && onChange(category.id);
                }}>
                    {category.id < 0 ? category.title
                        : category.id ? `${category.id}. ${category.title}`
                            : `⚠️ Без категории`}
                </MenuItem>
            )}
        </Menu>
    </Fragment>;
}