import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent, Stack, Typography
} from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import { DeleteOutline } from '@mui/icons-material';

interface FirstStepProps {
    schoolForm: any;
    handleSchoolChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    uploadedImages: any[],
    uploadedFiles: any[],
    handleImageEvent: (e: ChangeEvent<HTMLInputElement>) => void,
    handleFileEvent: (e: ChangeEvent<HTMLInputElement>) => void,
    handleCategoriesChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    nextStep: (step: number) => void,
    detach: (type: string, index: number) => void,
    regionsArray: any[],
    setRegion: (region: any) => void,
    localitiesArray: any[],
    setLocality: (locality: any) => void,
    typesArray: any[],
    setType: (type: any) => void
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const FirstStep = ({detach, schoolForm, nextStep, handleSchoolChange, registerLoading, uploadedImages,
                          uploadedFiles, handleImageEvent, handleFileEvent, handleCategoriesChange, regionsArray,
                          localitiesArray, setRegion, setLocality, typesArray, setType}: FirstStepProps) => {

    const imagesRef = useRef<HTMLInputElement>(null);
    const filesRef = useRef<HTMLInputElement>(null);

    const handleRegionChange = (e: SelectChangeEvent<any>) => {
        setRegion(e.target.value)
    }

    const handleLocalitiesChange = (e: SelectChangeEvent<string>) => {
        setLocality(e.target.value)
    }

    const handleTypeChange = (e: SelectChangeEvent<string>) => {
        setType(e.target.value)
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack flexDirection={'row'} sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Данные учебной организации</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={'БИН*'}
                        value={schoolForm.bin}
                        name={'bin'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Полное наименование организации*'}
                        value={schoolForm.title}
                        name={'title'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Ф.И.О. Директора*'}
                        value={schoolForm.director}
                        name={'director'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={'ИИН Директора*'}
                      value={schoolForm.director_iin}
                      name={'director_iin'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={'Электронная почта автошколы*'}
                      value={schoolForm.email}
                      name={'email'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Основной номер телефона*'}
                        value={schoolForm.phone1}
                        name={'phone1'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Дополнительный номер телефона'}
                        value={schoolForm.phone2}
                        name={'phone2'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <FormControl>
                        <InputLabel id={'type-label'}>Тип компании</InputLabel>
                        <Select
                          labelId={'type-label'}
                          onChange={handleTypeChange}
                          name={'type'}
                          label={'Тип компании'}
                          sx={{
                              borderRadius: '16px',
                              ".MuiOutlinedInput-notchedOutline": {
                                  borderWidth: 0,
                                  borderRadius: '16px',
                              }
                          }}
                          value={schoolForm.type}
                          required={true}
                        >
                            {typesArray.map((type: any) => {
                                return (
                                  <MenuItem
                                    value={type.value}
                                    key={type.value}
                                  >
                                      {type.name}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}}><b>Местоположение учебной организации</b></Typography>

                    <FormControl>
                        <InputLabel id={'region-label'}>Регион</InputLabel>
                        <Select
                          labelId={'region-label'}
                          onChange={handleRegionChange}
                          name={'region'}
                          label={'Регион'}
                          sx={{
                              borderRadius: '16px',
                              ".MuiOutlinedInput-notchedOutline": {
                                  borderWidth: 0,
                                  borderRadius: '16px',
                              }
                          }}
                          value={schoolForm.region}
                          required={true}
                        >
                            {regionsArray.map((region: any) => {
                                return (
                                  <MenuItem
                                    value={region.name_ru}
                                    key={region.name_ru}
                                  >
                                      {region.name_ru}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id={'locality-label'}>Населенный пункт</InputLabel>
                        <Select
                          labelId={'locality-label'}
                          onChange={handleLocalitiesChange}
                          name={'locality'}
                          label={'Населенный пункт'}
                          sx={{
                              borderRadius: '16px',
                              ".MuiOutlinedInput-notchedOutline": {
                                  borderWidth: 0,
                                  borderRadius: '16px',
                              }
                          }}
                          value={schoolForm.locality}
                          required={true}
                          disabled={localitiesArray.length === 0}
                        >
                            {localitiesArray.map((locality: any) => {
                                return (
                                  <MenuItem
                                    value={locality.name_ru}
                                    key={locality.name_ru}
                                  >
                                      {locality.name_ru}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <InputBase
                      type={'text'}
                      placeholder={'Адрес учебной организации'}
                      value={schoolForm.address}
                      name={'address'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={false}
                      disabled={registerLoading}
                    />
                </Stack>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <Button
                    variant={'contained'}
                    sx={{width: '433px', height: '56px', mt: '33px'}}
                    onClick={() => {nextStep(1)}}
                >
                    Далее
                </Button>
            </Stack>
        </Stack>
    )
};