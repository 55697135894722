import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps, Typography, FormHelperText } from '@mui/material';
import React, {useEffect, useId, useState} from 'react';
import {getAllCategories} from "../../actions/api";
import {CategoryDto} from "../../../School/actions/category.type";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";

interface Props {
    onChange: (value: CategoryValue) => void,
    value: CategoryValue,
    label?: string;
}

type CategoryValue = CategoryDto['id'] | null;

export default function CategorySelect(
    {onChange, value, label = 'Категория', ...props}: Props & Omit<SelectProps, 'onChange'>
) {

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [categories, setCategories] = useState<CategoryDto[]>([]);

    useEffect(() => {
        console.log(`CategorySelect ререндер`)
        if (schoolInfo.id) {
            getAllCategories(schoolInfo.id)
                .then((res) => {
                    const categories = res.data.map((category: any) => {
                        return category.category;
                    })

                    setCategories(categories);
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
        }

        if (!schoolInfo.id) {
            snackbar.errorMessage('Невозможно получить список категорий. Перезайдите на страницу с таблицей групп.')
        }

    }, [])

    const categoriesOrder: string[] = ['A', 'B', 'BE', 'C', 'CE', 'D', 'DE', 'Tb', 'Tm', 'A1', 'B1', 'C1', 'C1E', 'D1', 'BC1'];

    const sortCategories = (array: any[]) => {
        return array.sort((a, b) => {
            const indexA = categoriesOrder.indexOf(a.category_id);
            const indexB = categoriesOrder.indexOf(b.category_id);

            if (indexA === -1 || indexB === -1) {
                return 0; // Если какая-то из категорий отсутствует в массиве с порядком, оставляем их в том же порядке
            }

            return indexA - indexB; // Сортируем по индексу в массиве с порядком
        });
    }

    return (
        <FormControl fullWidth sx={{marginTop: '1rem'}}>
            <Select
                id={'new-group-select'}
                value={value || ''}
                onChange={(event) => { // @ts-ignore
                    onChange(event.target.value)}}
                {...props}
            >
                {categories.length > 0 && sortCategories(categories).map((category) =>
                    <MenuItem value={category.id} key={category.id}>
                        <Box>
                            <Typography>{category.id}</Typography>
                            <Typography variant="body2" color="gray">
                                Длительность: {category.education_days} дней
                            </Typography>
                        </Box>
                    </MenuItem>
                )}
            </Select>
            <FormHelperText>Выберите необходимую категорию водительских прав</FormHelperText>
        </FormControl>
    );
}