
export enum TestPassCategory {
    Demo = 0,
    A = 1,
    B = 2,
    C = 3,
    BC = 8,
    D = 4,
    CD = 5,
    TM = 6,
}

export enum TestPassStatus {
    InProgress = 1,
    Passed = 2,
    Failed = 3,
    Cancelled = 4,
    FailedFinish = 5,
    DemoLimit = 6,
    LobbyEnded = 7
}

export class TestPass {
    public _abilityName? = 'TestPass';
    id?: number;
    user_id?: number;
    category?: TestPassCategory;

    constructor(props?: TestPass | null) {
        if (props) Object.assign(this, props);
    }
}


export const FormattedTestCategories: { [key in TestPassCategory]: string } = {
    [TestPassCategory.Demo]: 'Демонстрационный тест',
    [TestPassCategory.A]: 'Категории A1, A, B1',
    [TestPassCategory.B]: 'Категории B, BE',
    [TestPassCategory.C]: 'Категории C1, C',
    [TestPassCategory.BC]: 'Категория BC1',
    [TestPassCategory.D]: 'Категории D1, D, Tb',
    [TestPassCategory.CD]: 'Категории C1E, CE, D1E, DE',
    [TestPassCategory.TM]: 'Категория Tm'
};
export const FormattedTestCategoriesForSale = {
    [TestPassCategory.A]: 'Категории A1, A, B1',
    [TestPassCategory.B]: 'Категории B, BE',
    [TestPassCategory.C]: 'Категории C1, C',
    [TestPassCategory.BC]: 'Категория BC1',
    [TestPassCategory.D]: 'Категории D1, D, Tb',
    [TestPassCategory.CD]: 'Категории C1E, CE, D1E, DE',
    [TestPassCategory.TM]: 'Категория Tm'
};
export const FormattedTestCategoriesForSale2 = {
    [TestPassCategory.A]: 'Категории A1, A, B1',
    [TestPassCategory.B]: 'Категории B, BE',
    [TestPassCategory.C]: 'Категории C1, C',
    [TestPassCategory.BC]: 'Категория BC1',
    [TestPassCategory.D]: 'Категории D1, D, Tb',
    [TestPassCategory.CD]: 'Категории C1E, CE, D1E, DE',
    [TestPassCategory.TM]: 'Категория Tm'
};

export const FormattedTestCategories2: { [key in TestPassCategory]: string } = {
    [TestPassCategory.Demo]: 'Демонстрационный тест',
    [TestPassCategory.A]: 'A1, A, B1',
    [TestPassCategory.B]: 'B, BE',
    [TestPassCategory.C]: 'C1, C',
    [TestPassCategory.BC]: 'BC1',
    [TestPassCategory.D]: 'D1, D, Tb',
    [TestPassCategory.CD]: 'C1E, CE, D1E, DE',
    [TestPassCategory.TM]: 'Tm'
};

export interface TestPassAnswerDto {
    question_id: number;
    answer_id: number;
    is_correct: boolean;
}

export interface UserTestDto {
    id: number,
    username: string | null,
    iin: string | null,
    email: string | null,
    phone: string | null,
    first_name: string | null,
    last_name: string | null,
    patronymic: string | null,
    birthday: Date | null,
    address: string | null,
    verified: boolean,
    balance: number;
    avatar: string | null,
    created_at: Date,
}
export interface TestPassDto {
    id: number;
    user_id: number;
    user?: UserTestDto;
    questions_count: number;
    questions_to_fail: number;
    category: TestPassCategory;
    correct_count: number;
    incorrect_count: number;
    status: TestPassStatus;
    answers_history?: TestPassAnswerDto[];
    start_at: Date;
    end_at: Date | null;
    expires_at: Date | null;
    lobby_id?: string;
    current_question_index: number;
    unanswered_count: number;
}

export interface MultiLanguageDto {
    ru: string | null;
    kz: string | null;
    en: string | null;
    az: string | null;
}

export interface TestPassQuestionDto_File {
    id: string | null;
    size: number | null;
}

export interface TestPassQuestionDto_Answer {
    id: number;
    is_correct: boolean | null;
    title: MultiLanguageDto;
}

export interface TestPassQuestionDto {
    id: number;
    question: MultiLanguageDto;
    explanation: MultiLanguageDto | null;
    question_file: TestPassQuestionDto_File;
    explanation_file: TestPassQuestionDto_File | null;
    explanation2_file: TestPassQuestionDto_File | null;
    answers: TestPassQuestionDto_Answer[];
}

export interface TestPassStateDto {
    test: TestPassDto;
    question: TestPassQuestionDto | null;
}
