import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {ProductBuyDto, ProductCheckDto, ScheduleCreateDto, ServiceCreateDto} from "./driving.dto";
import {AddEmployeeToDrivingDto, DrivingEmployeesDto} from "../../Employees/actions/employee.type";

export const checkIsDrivingAvailable = (dto: ProductCheckDto) => {
    return axiosInstance.post(`${BACKEND_URL}/products/check`, dto)
}

export const getProductInfo = (productId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/products/${productId}`);
}

export const buyDrivingModuleOneMonth = (dto: ProductBuyDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transactions/buy-from-balance`, dto);
}

// Сотрудники
export const getActiveSchoolMasters = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/master/active/${id}`);
}
export const getActiveSchoolDrivers = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/driver/active/${id}`);
}
export const getDrivingEmployees = (dto: DrivingEmployeesDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/appointment/get/employees`, dto);
}
export const attachEmployeeToDriving = (dto: AddEmployeeToDrivingDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/appointment/create`, dto);
}

// Расписания
export const createScheduleByEmployeeId = (dto: ScheduleCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/schedules/create`, dto);
}
export const getDrivingEmployeesWithSchedules = (dto: DrivingEmployeesDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/appointment/get/employees-schedules`, dto);
}
export const getEmployeeWithSchedule = (employeeId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schedules/get/${employeeId}`);
}

// Услуги
export const getDrivingEmployeesWithClasses = (dto: DrivingEmployeesDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/appointment/get/employees-classes`, dto);
}
export const getDrivingServicesByEmployeeId = (employeeId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/class/get/${employeeId}`);
}
export const addServiceByEmployeeId = (dto: ServiceCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/class/create`, dto);
}

// Записи
export const getAppointmentById = (appointmentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/appointment/get/${appointmentId}`);
};
export const changeStatusByAppointmentId = (appointmentId: number, status: number) => {
    return axiosInstance.post(`${BACKEND_URL}/appointment/change-status/${appointmentId}`, {status});
}
export const getAllDrivingAppointmentsBySchoolId = (schoolId: number, status: number) => {
    return axiosInstance.get(`${BACKEND_URL}/appointment/get/school/${schoolId}?status=${status}`);
};
export const getDrivingAppointmentsByEmployeeId = (employeeId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/appointment/get/employee/${employeeId}`)
}