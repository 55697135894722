import React, {useEffect, useState} from 'react';
import {Avatar, Box, Stack, Typography} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useSchoolStore} from "../store/SchoolStore";
import {getSchoolBalance, getSchoolInfo, getSchoolTransactions} from "../actions/api";
import {DirectionsCar} from "@mui/icons-material";
import TransactionsGrid from '../components/TransactionsGrid';

const SchoolTransactions = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const snackbar = useSnackbarStore((state) => state);
    const [balance, setBalance] = useState<number>(0);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [schoolInfo, setSchoolInfo] = useState<any>({
        address: '',
        avatar: null,
        balance: 0,
        bin: '',
        categories: [],
        director: '',
        fts: '',
        id: 0,
        phone1: '',
        phone2: '',
        title: '',
        region: '',
        locality: '',
        user: {
            iin: '',
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            patronymic: ''
        }
    });

    useEffect(() => {
        fetchSchoolInfo();
    }, []);

    const fetchSchoolInfo = () => {
        getSchoolTransactions(Number(id))
            .then((res) => {
                console.log(res);
                setTransactions(res.data);
            })
        getSchoolBalance(Number(id))
            .then((res) => {
                setBalance(res.data.balance);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
                navigate('/schools');
            })
        getSchoolInfo(Number(id))
            .then((res) => {
                setSchoolInfo(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
                navigate('/schools');
            })
    }

    return (

        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Typography sx={{mt: 3}} variant={'h5'}><b>История транзакций</b></Typography>
                <Typography variant={'subtitle2'}>Здесь отображаются все пополнения и покупки автошколы</Typography>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo.title}
                        </Typography>
                    </Stack>

                    <Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}} ><b>Баланс</b></Typography>
                        <Stack flexDirection={'row'} alignItems='center'>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>Текущий баланс - <b>{balance} тг.</b> </Typography>
                        </Stack>

                    </Box>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: '100%',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <TransactionsGrid 
                        rows={transactions}
                    />
                </Stack>
            </Box>
        </Box>
    );
};

export default SchoolTransactions;