import { ReactNode } from 'react';
import { Stack, StackProps, SxProps, Theme } from '@mui/material';

interface Props {
    children: ReactNode;
    alignItems?: StackProps['alignItems'],
    justifyContent?: StackProps['justifyContent'],
    sx?: SxProps<Theme>;
}

export default function FlexHeader(
    {children, alignItems = 'center', justifyContent = 'space-between', sx = {}}: Props
) {
    return (
        <Stack flexDirection="row" justifyContent={justifyContent} flexWrap="wrap" alignItems={alignItems}
               sx={{mb: 1, ...sx}}>
            {children}
        </Stack>
    );
}