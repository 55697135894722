import React, { useEffect } from 'react';
import PageHeader from "../../global/components/PageHeader";
import {Avatar, Box, Stack, Typography} from "@mui/material";
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { getDrivingEmployees, getDrivingEmployeesWithSchedules } from '../actions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getName } from '../../global/helpers/text';
import NoRows from '../../global/components/NoRows';
import MiniSchedule from '../components/MiniSchedule';

const DrivingSchedules = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [employeesList, employeesLoading, , fetchEmployees] = useLoadingHook(getDrivingEmployeesWithSchedules);

    useEffect(() => {
        fetchEmployees({
            school_id: Number(id),
            product_id: 1
        });
    }, []);

    useEffect(() => {
        console.log(employeesList);
    }, [employeesList])

    const columnsForDriving: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 360, sortable: true,
            renderCell: ({row}) => {
                const employeeInfo = row.type === 'driver' ? {
                    ...row.driver
                } : {
                    ...row.master
                };

                const name = getName(employeeInfo);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'schedule',
            headerName: 'Расписание',
            minWidth: 400,
            flex: 1,
            renderCell: ({row}) => {
                return <MiniSchedule days={row.schedule.days} hours={row.schedule.hours}/>
            }
        }
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Управление расписаниями сотрудников</b></Typography>
                    </Stack>
                    <Typography variant="subtitle1">
                        Здесь отображаются все расписания записей на вождения по сотрудникам. Для изменения расписания - нажмите на сотрудника.
                    </Typography>

                    <DataGrid
                            columns={columnsForDriving}
                            rows={employeesList ? employeesList : []}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            loading={employeesLoading}
                            autoHeight={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            style={{margin: '0', height: '100%', width: '100%'}}
                            slots={{
                                noRowsOverlay: NoRows
                            }}
                            getRowHeight={() => 'auto'} 
                            getRowClassName={() => 'dataGridRow'}
                            onRowClick={({row}) => {navigate(`/schools/${id}/driving/schedules/${row.id}`)}}
                        />
                </Box>
            </Box>
        </Box>
    );
};

export default DrivingSchedules;