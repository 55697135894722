import React from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {Avatar, Box, Button, Stack, Tooltip, Typography} from "@mui/material";
import {formatDate, formatDateTime, getName} from "../../global/helpers/text";
import {DirectionsCar} from "@mui/icons-material";
import {GroupStatusChip} from "../../Group/components/GroupsList/GroupStatusChip";
import NoRows from '../../global/components/NoRows';
import {FRONTEND_URL} from "../../global/actions/apiVariables";
import InfoIcon from "@mui/icons-material/Info";

interface KursantCertificatesProps {
    rows: any[],
    loading: boolean
}

const KursantCertificates = ({rows, loading} : KursantCertificatesProps) => {

    const renderCertificate = (row: GridRowModel<any>) => {
        if (!row.passed) {
            return <Typography style={{color: 'red'}}>Необходима успешная сдача</Typography>
        }

        // TODO: Разблокировать когда будут разработаны модули оплаты и баланса, доработать после модуля оплаты
        if (row.passed) {
            return (
                <Stack flexDirection={'row'} alignItems={'center'}>
                    <Button
                        href={`${FRONTEND_URL}/certificate/${row.id}`}
                        variant={'contained'}
                        sx={{height: 'auto'}}
                    >
                        Посмотреть информацию
                    </Button>
                </Stack>
            )
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: 'ИИН',
            width: 150,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: 'Дата прикрепления',
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'passed',
            headerName: 'Статус сдачи',
            width: 150,
            sortable: true,
            valueGetter: ({row}) => {return row.passed ? 'Сдал' : 'Не сдал'}
        },
        {
            field: 'certification',
            headerName: 'Выдача сертификата',
            width: 250,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => renderCertificate(row)
        },
        {
            field: 'certification_number',
            headerName: 'Номер сертификата',
            width: 250,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => row.certificate_number ? `QZ ${row.certificate_number}` : 'Отсутствует'
        }
    ];

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                loading={loading}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default KursantCertificates;