import {Avatar, Box, Stack, Tooltip, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {formatDate} from "../../../global/helpers/text";
import {GroupStatusChip} from "../GroupsList/GroupStatusChip";
import {GroupDto, GroupStatus} from "../../actions/group.type";

interface GroupHeaderProps {
    groupInfo: GroupDto;
}

export const GroupStatusHelperText = <Box>
    <Typography variant="body2">Статусы групп:</Typography>
    <Typography variant="body2" sx={{mt: 1}}>
        <b>Идёт набор</b> — статус по умолчанию после создания группы. На этом этапе
        прикрепите всех людей к группе и затем сформируйте её
    </Typography>
    <Typography variant="body2" sx={{mt: 1}}>
        <b>Идёт обучение</b> — группа сформирована, её больше нельзя отредактировать. Когда наступит дата
        окончания обучения, группа автоматически перейдет в статус «Экзамены»
    </Typography>
    <Typography variant="body2" sx={{mt: 1}}>
        <b>Экзамены</b> — обучение в группе завершено, необходимо провести экзаменацию курсантов
    </Typography>
    <Typography variant="body2" sx={{mt: 1}}>
        <b>Завершена</b> — группа завершена, экзаменация курсантов проведена
    </Typography>
</Box>;

export function GroupHeader({groupInfo} : GroupHeaderProps) {

    return (
        <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between" sx={{width: '100%', mb: 3}}
               flexWrap="wrap">
            <Stack flexDirection="row" alignItems="center" flexWrap="wrap" sx={{mt: 1}}>
                <Avatar
                    sx={{fontWeight: 500, mr: 2, color: '#99999F', bgcolor: '#D9E8FF', width: 50, height: 50}}
                >
                    {groupInfo.category_id}
                </Avatar>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">Группа</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.title}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">ID</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.id}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">Категория</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.category_id}</Typography>
                </Stack>
                <Stack sx={{mr: 8}}>
                    <Typography variant="h6" color="gray">Статус</Typography>
                    <Tooltip title={GroupStatusHelperText} arrow>
                        <Typography variant="h5" sx={{
                            mt: 0.3, textDecoration: 'underline',
                            textDecorationStyle: 'dotted'
                        }}>
                            <GroupStatusChip status={groupInfo.status} variant="text"/>
                        </Typography>
                    </Tooltip>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">Дата начала</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{formatDate(groupInfo.start_date)}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">Дата окончания</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{formatDate(groupInfo.end_date)}</Typography>
                </Stack>
            </Stack>
            <Stack flexDirection="column" alignItems="flex-end">

            </Stack>
        </Stack>
    );
}