import axios from "axios";
import {apiConfigGuest, apiConfigReg, BACKEND_URL} from "../../global/actions/apiVariables";
import {AuthDto, KursantLoginDto, LoginDto} from "../dto/AuthDto";
import axiosInstance from "../../global/actions/main.api";

export const checkEmail = (userLoginDto: LoginDto) => {
    return axiosInstance.post(`${BACKEND_URL}/auth/check`, userLoginDto, apiConfigGuest);
}

export const login = (userAuthDto: AuthDto) => {
    return axiosInstance.post(`${BACKEND_URL}/auth/login`, userAuthDto, apiConfigGuest);
}

export const register = (regFormData: FormData) => {
    return axiosInstance.post(`${BACKEND_URL}/auth/register`, regFormData, apiConfigReg)
}

export const registerByAdmin = (regFormData: FormData) => {
    return axiosInstance.post(`${BACKEND_URL}/auth/register-by-admin`, regFormData);
}

export const getRegions = () => {
    return axiosInstance.get(`${BACKEND_URL}/region`)
}

export const getLocalitiesByRegionId = (regionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/region/${regionId}`)
}