import React, {useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {BackLink} from "../../../global/components/BackLink";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {reportQuestion} from "../../actions/api";
interface ModalProps {
    questionId: number;
    open: boolean,
    close: () => void,
}

type Form = 'menu' | 'video_trouble' | 'incorrect_question'

export const QuestionReportDialog = ({questionId, open, close}: ModalProps) => {
    const snackbar = useSnackbarStore((state) => state);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [form, setForm] = useState<Form>('menu');
    const isReady = message?.length > 3;
    const save = () => {
        if (!isReady) return snackbar.errorMessage('Заполните все поля');
        reportQuestion(questionId, {message: message})
            .then(r => {
                snackbar.successMessage(`Сообщение успешно отправлено!`);
                close();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const MenuForm = <Box>
        <Typography variant="h4" sx={{mb: 2}}>Какая у вас проблема?</Typography>
        <Stack flexDirection="column" alignItems="center" sx={{width: '100%'}}>
            <Button variant="contained" onClick={() => setForm('video_trouble')} sx={{mb: 1}}>
                Не загружается видео
            </Button>

            <Button variant="contained" onClick={() => setForm('incorrect_question')}>
                Ошибка в вопросе
            </Button>
        </Stack>
    </Box>;

    const VideoForm = <Box>
        <BackLink onClick={() => setForm('menu')} sx={{mb: 1.5}}/>
        <Typography variant="h4" sx={{mb: 1}}>Не загружается видео</Typography>
        <Typography sx={{mb: 1}}>
            Уважаемые пользователи, режим энергосбережения на вашем устройстве может помешать воспроизведению видео.
            При прохождении тестов отключите энергосбережение и перезагрузите страницу
        </Typography>
        <Typography sx={{mb: 1}}>
            Если проблема осталась, попробуйте зайти на сайт с другого браузера
        </Typography>
    </Box>;

    const IncorrectForm = <Box>
        <BackLink onClick={() => setForm('menu')} sx={{mb: 1.5}}/>
        <Typography variant="h4" sx={{mb: 1}}>Ошибка в вопросе</Typography>
        <Typography sx={{mb: 1}}>
            Обратите внимание! Если вы ранее отвечали на этот вопрос, а сейчас ответ находится
            под другой буквой — это связано с тем, что сайт перемешивает варианты ответов, и это нормально
        </Typography>
        <Typography sx={{mb: 1}}>Введите сообщение с описанием ошибки</Typography>
        <TextField
            label="Сообщение" fullWidth multiline minRows={3} required sx={{mb: 2}}
            value={message} onChange={(e) => setMessage(e.currentTarget.value)}
        />
    </Box>;

    const forms: { [key in Form]: any } = {
        'menu': MenuForm,
        'video_trouble': VideoForm,
        'incorrect_question': IncorrectForm
    };

    return <Dialog open={open} onClose={() => close()} maxWidth="sm">
        <DialogTitle>Сообщить об ошибке в вопросе #{questionId}</DialogTitle>
        <DialogContent>
            <Box sx={{maxWidth: 500}}>
                {forms[form]}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => close()}>Закрыть</Button>
            <LoadingButton onClick={save} variant="contained" disabled={!isReady} loading={loading}>
                Отправить
            </LoadingButton>
        </DialogActions>
    </Dialog>;
};