interface Props {
    width?: number,
    height?: number,
    color: string
}

export default function Track({width = 22, height = 22, color}: Props) {
    return <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6 1.59998C16.9237 1.59998 18 2.67435 18 3.99998V5.79998H19.83C20.46 5.79998 21.0563 6.08123 21.4575 6.56873L23.6287 9.26498C23.88 9.53123 24 9.86873 24 10.2137V13.6C24 14.2637 23.4638 14.8 22.8 14.8H22.74C22.7775 14.995 22.8 15.1937 22.8 15.4C22.8 17.0575 21.4575 18.4 19.8 18.4C18.1425 18.4 16.8 17.0575 16.8 15.4C16.8 15.1937 16.8225 14.995 16.86 14.8H10.74C10.7775 14.995 10.8 15.1937 10.8 15.4C10.8 17.0575 9.4575 18.4 7.8 18.4C6.8175 18.4 5.9475 17.9275 5.4 17.2C4.8525 17.9275 3.9825 18.4 3 18.4C1.34325 18.4 0 17.0575 0 15.4V3.99998C0 2.67435 1.07438 1.59998 2.4 1.59998H15.6ZM20.0625 7.70873C20.034 7.67481 19.9984 7.64751 19.9582 7.62873C19.9181 7.60995 19.8743 7.60014 19.83 7.59998H18V9.99998H21.9037L20.0625 7.70873ZM19.8 16.6C20.4638 16.6 21 16.0637 21 15.4C21 14.7362 20.4638 14.2 19.8 14.2C19.1363 14.2 18.6 14.7362 18.6 15.4C18.6 16.0637 19.1363 16.6 19.8 16.6ZM7.8 14.2C7.13625 14.2 6.6 14.7362 6.6 15.4C6.6 16.0637 7.13625 16.6 7.8 16.6C8.46375 16.6 9 16.0637 9 15.4C9 14.7362 8.46375 14.2 7.8 14.2ZM3 16.6C3.66263 16.6 4.2 16.0637 4.2 15.4C4.2 14.7362 3.66263 14.2 3 14.2C2.33738 14.2 1.8 14.7362 1.8 15.4C1.8 16.0637 2.33738 16.6 3 16.6Z" fill={color}/>
    </svg>
}