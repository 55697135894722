interface Props {
    width?: number,
    height?: number,
    color: string
}

export default function Bus({width = 22, height = 22, color}: Props) {
    return <svg width={width} height={height} viewBox="0 0 26 14" fill='#044EBD' xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.36364 0.175049C1.05182 0.175049 0 1.18742 0 2.45005V10.4125H2.36364C2.36364 11.3176 2.73717 12.1856 3.40208 12.8256C4.06698 13.4655 4.96878 13.825 5.90909 13.825C6.8494 13.825 7.7512 13.4655 8.41611 12.8256C9.08101 12.1856 9.45455 11.3176 9.45455 10.4125H16.5455C16.5455 11.3176 16.919 12.1856 17.5839 12.8256C18.2488 13.4655 19.1506 13.825 20.0909 13.825C21.0312 13.825 21.933 13.4655 22.5979 12.8256C23.2628 12.1856 23.6364 11.3176 23.6364 10.4125H26V2.45005C26 1.18742 24.9482 0.175049 23.6364 0.175049H2.36364ZM1.77273 1.8813H6.5V4.72505H1.77273V1.8813ZM8.27273 1.8813H13V4.72505H8.27273V1.8813ZM14.7727 1.8813H19.5V4.72505H14.7727V1.8813ZM21.2727 1.8813H24.2273V8.13755L21.2727 5.86255V1.8813ZM5.90909 8.7063C6.37925 8.7063 6.83015 8.88606 7.1626 9.20605C7.49505 9.52603 7.68182 9.96002 7.68182 10.4125C7.68182 10.8651 7.49505 11.2991 7.1626 11.619C6.83015 11.939 6.37925 12.1188 5.90909 12.1188C5.43893 12.1188 4.98803 11.939 4.65558 11.619C4.32313 11.2991 4.13636 10.8651 4.13636 10.4125C4.13636 9.96002 4.32313 9.52603 4.65558 9.20605C4.98803 8.88606 5.43893 8.7063 5.90909 8.7063ZM20.0909 8.7063C20.5611 8.7063 21.012 8.88606 21.3444 9.20605C21.6769 9.52603 21.8636 9.96002 21.8636 10.4125C21.8636 10.8651 21.6769 11.2991 21.3444 11.619C21.012 11.939 20.5611 12.1188 20.0909 12.1188C19.6208 12.1188 19.1699 11.939 18.8374 11.619C18.505 11.2991 18.3182 10.8651 18.3182 10.4125C18.3182 9.96002 18.505 9.52603 18.8374 9.20605C19.1699 8.88606 19.6208 8.7063 20.0909 8.7063Z"
            fill={color}/>
    </svg>;
}