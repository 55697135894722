import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, FormHelperText, InputBase, Stack, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { GroupChangeTitleDto, GroupDto } from '../../actions/group.type';
import { changeGroupTitle } from '../../actions/api';
import { useSnackbarStore } from '../../../global/store/SnackbarStore';
import {ExpandMoreOutlined} from "@mui/icons-material";

interface AdminChangeTitleProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

const AdminChangeTitle = ({groupInfo, fetchGroupInfo}: AdminChangeTitleProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [dto, setDto] = useState<GroupChangeTitleDto>({
        group_id: 500000,
        title: ''
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setDto((prev) => {
            return {
                ...prev,
                group_id: Number(groupInfo.id),
                title: groupInfo.title
            }
        })
    }, [groupInfo]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e);
        setDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleSubmit = () => {
        setLoading(true);
        changeGroupTitle(dto)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка');
        })
        .finally(() => {
            fetchGroupInfo();
            setLoading(false);
        })
    }

    return (
        <Accordion sx={{width: '100%'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                <Typography>
                    Изменить название группы
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    <FormControl>
                        <InputBase 
                            type='text'
                            value={dto.title}
                            name='title'
                            onChange={handleTitleChange}
                        />
                        <FormHelperText>Дата начала обучения</FormHelperText>
                    </FormControl>
                    {!loading && <Button
                        variant='contained'
                        onClick={() => {handleSubmit()}}
                    >
                        Сохранить
                    </Button>}
                    {loading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress/></Stack>}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default AdminChangeTitle;