interface Props {
    width?: number,
    height?: number,
    color: string
}

export default function Tram({width = 22, height = 22, color}: Props) {
    return <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.23421 24.4322C7.88699 24.3454 7.66581 24.1284 7.57067 23.7812C7.47484 23.434 7.54845 23.1388 7.7915 22.8958L8.31234 22.375C7.28803 22.375 6.42449 22.0236 5.72171 21.3208C5.01824 20.6173 4.6665 19.7534 4.6665 18.7291V8.83329C4.6665 7.14926 5.40435 6.04683 6.88005 5.526C8.35574 5.00517 10.0484 4.71871 11.9582 4.66663L12.7394 3.10413H8.57275C8.34706 3.10413 8.1606 3.03017 8.01338 2.88225C7.86546 2.73503 7.7915 2.54857 7.7915 2.32288C7.7915 2.09718 7.86546 1.91038 8.01338 1.76246C8.1606 1.61524 8.34706 1.54163 8.57275 1.54163H17.4269C17.6526 1.54163 17.8394 1.61524 17.9873 1.76246C18.1346 1.91038 18.2082 2.09718 18.2082 2.32288C18.2082 2.54857 18.1346 2.73503 17.9873 2.88225C17.8394 3.03017 17.6526 3.10413 17.4269 3.10413H14.8228L14.0415 4.66663C16.1075 4.71871 17.8394 5.00065 19.2373 5.51246C20.6346 6.02496 21.3332 7.1319 21.3332 8.83329V18.7291C21.3332 19.7534 20.9818 20.6173 20.279 21.3208C19.5755 22.0236 18.7116 22.375 17.6873 22.375L18.2082 22.8958C18.4512 23.1388 18.5248 23.434 18.429 23.7812C18.3339 24.1284 18.1127 24.3454 17.7655 24.4322C17.6092 24.467 17.4575 24.467 17.3103 24.4322C17.1623 24.3975 17.0276 24.3194 16.9061 24.1979L15.0832 22.375H10.9165L9.09359 24.1979C8.97206 24.3194 8.83768 24.3975 8.69046 24.4322C8.54255 24.467 8.39046 24.467 8.23421 24.4322ZM12.9998 19.25C13.4339 19.25 13.8026 19.0979 14.1061 18.7937C14.4103 18.4902 14.5623 18.1215 14.5623 17.6875C14.5623 17.2534 14.4103 16.8847 14.1061 16.5812C13.8026 16.277 13.4339 16.125 12.9998 16.125C12.5658 16.125 12.1971 16.277 11.8936 16.5812C11.5894 16.8847 11.4373 17.2534 11.4373 17.6875C11.4373 18.1215 11.5894 18.4902 11.8936 18.7937C12.1971 19.0979 12.5658 19.25 12.9998 19.25ZM6.74984 13H19.2498V9.87496H6.74984V13Z"
            fill={color}/>
    </svg>;
}