import { Box, SxProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MouseEventHandler } from 'react';

interface Props {
    sx?: SxProps,
    label?: string,
    onClick?: MouseEventHandler,
}

export const BackLink = ({sx = {}, label = 'Назад', onClick}: Props) => {
    return <Box sx={{
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        color: '#248AE8',
        cursor: 'pointer', ...sx
    }} onClick={onClick}>
        <ArrowBackIcon sx={{mr: 1}}/> {label}
    </Box>;
};