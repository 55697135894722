import React, {useState, useEffect, FormEvent} from 'react';
import {
    Container,
    Grid,
    Chip,
    useMediaQuery,
    useTheme,
    TextField,
    Typography,
    Dialog,
    DialogTitle, Button, DialogContent, Stack
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { getAvailabeDaysByEmployeeId, getAvailableHoursByDateAndEmployeeId } from '../actions/api';
import EmployeeCard from '../../Driving/components/EmployeeCard';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import FinishAppointment from "./FinishAppointment";

interface ScheduleItem {
    time: string,
    available: boolean
}

interface AppointmentDateTimeProps {
    employeeId: number,
    iin: string,
    last_name: string,
    fetchKursantInfo: () => void,
    mainDialogClose: () => void,
}

function AppointmentDateTime({ employeeId, iin, last_name, fetchKursantInfo, mainDialogClose}: AppointmentDateTimeProps) {
    const [scheduleInfo, scheduleInfoLoading, , fetchScheduleInfo] = useLoadingHook(getAvailabeDaysByEmployeeId)
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>('');
    const [schedule, setSchedule] = useState<ScheduleItem[]>([]);
    const [availableDays, setAvailableDays] = useState<string[]>([]);
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchScheduleInfo(employeeId);
        setSchedule([]);
    }, [employeeId]);

    useEffect(() => {
        if (scheduleInfo) {
            setAvailableDays(scheduleInfo.days);
        }
    }, [scheduleInfo]);

    useEffect(() => {
        setSelectedTime('');
        if (selectedDate) {
            getAvailableHoursByDateAndEmployeeId(selectedDate, employeeId)
                .then((res) => {
                    setSchedule(res.data);
                })
        }
    }, [selectedDate]);

    const fetchSchedule = async (date: Dayjs) => {
        const response = await fetch(`/api/schedule?employeeId=${employeeId}&date=${date}`);
        const data: ScheduleItem[] = await response.json();
        setSchedule(data);
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
        if(date) fetchSchedule(date);
    };

    const handleTimeChange = (time: any) => {
        setSelectedTime(time);
        console.log(selectedDate?.toDate());
    };

    const disableUnvailableDays = (date: Dayjs) => {
        const dayNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
        const dayName = dayNames[date.day()];
        return !availableDays.includes(dayName);
    };

    const setVariantForTimeChip = (schedule: any) => {
        if (schedule.time === selectedTime) {
            return 'filled'
        } else {
            return 'outlined'
        }

        return 'outlined'
    };

    return (
        <Container>
            <Grid container gap={3} direction={isMobile ? 'column' : 'row'}>
                <Grid item xs={12} md={6}>
                    <Stack gap={2}>
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography>Выбранный инструктор - </Typography>
                            {scheduleInfo && <EmployeeCard employeeInfo={scheduleInfo.employee.type === 'master' ? scheduleInfo.employee.master : scheduleInfo.employee.driver}/>}
                        </Stack>

                        <DatePicker
                            label="Выберите дату"
                            value={selectedDate}
                            onChange={handleDateChange}
                            shouldDisableDate={disableUnvailableDays}
                        />
                    </Stack>
                </Grid>
                {selectedDate && (
                    <Grid item xs={12} md={6}>
                        {schedule.map((item) => (
                            <Chip
                                key={item.time}
                                label={item.time}
                                clickable={item.available}
                                onClick={item.available ? () => {handleTimeChange(item.time)} : () => {}}
                                variant={setVariantForTimeChip(item)}
                                color={item.available ? 'primary' : 'default'}
                                sx={{m: 1}}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>

            {selectedTime && (
                <Button
                    variant={'contained'}
                    onClick={() => setCreateDialogOpen(true)}
                    sx={{mt: 2}}
                >
                    Записаться на {selectedTime}
                </Button>
            )}

            <FinishAppointment
                employeeId={employeeId}
                open={createDialogOpen}
                onClose={() => {setCreateDialogOpen(false)}}
                selectedDate={selectedDate ? selectedDate : dayjs()}
                selectedTime={selectedTime ? selectedTime : ''}
                fetchKursantInfo={fetchKursantInfo}
                mainDialogClose={mainDialogClose}
            />
        </Container>
    );
}

export default AppointmentDateTime;
