import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {TicketCreateDto, TicketMessageSendDto} from "./tickets.dto";

export const getAllTickets = () => {
    return axiosInstance.get(`${BACKEND_URL}/tickets/get/all`);
}

export const getTicketsByStatus = (status: number) => {
    return axiosInstance.post(`${BACKEND_URL}/tickets/get/all/status`, {status});
}

export const getUserTickets = () => {
    return axiosInstance.get(`${BACKEND_URL}/tickets/get/my`);
}

export const createTicket = (formData: TicketCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/tickets/create`, formData);
}

export const getTicketInfo = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/tickets/get/${id}`);
}

export const sendMessage = (ticketId: number, formData: TicketMessageSendDto) => {
    return axiosInstance.post(`${BACKEND_URL}/tickets/message/${ticketId}`, formData);
}

export const closeTicketByAdmin = (ticketId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/tickets/close/${ticketId}`, {});
}