import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { getSchoolCabinets, submitCabinet } from '../actions/api';
import LoadingButton from '../../global/components/LoadingButton';
import { useSnackbarStore } from '../../global/store/SnackbarStore';

interface AddCabinetModalProps {
  open: boolean,
  setOpen: (st: boolean) => void,
  updateCabinets: () => void
}

const AddCabinetModal = ({open, setOpen, updateCabinets} : AddCabinetModalProps) => {

  const {id} = useParams();
  const snackbar = useSnackbarStore((state) => state);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [submitResult, submitLoading, submitError, submit] = useLoadingHook(submitCabinet);
  const [cabinetDto, setCabinetDto] = useState<any>({
    school_id: Number(id),
    address: '',
    type: 'osh',
  });
  const [uploadedPdf, setUploadedPdf] = useState<File | null>(null);

  const handleAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCabinetDto((prev: any) => {
      return {
        ...prev,
        address: e.target.value
      }
    })
  };

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      // Ограничение размера файла в байтах (5 MB)
      const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (file.size > maxFileSize) {
        // Если файл превышает лимит, вы можете, например, уведомить пользователя
        alert("Размер файла не должен превышать 5 MB.");
        setUploadedPdf(null)
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        // Если размер файла в пределах допустимого, устанавливаем файл
        setUploadedPdf(file);
      }
    } else {
      // Если файл не выбран, сбрасываем состояние
      setUploadedPdf(null);
    }
  };

  const finishAppeal = () => {
    const formData = new FormData();

    for (let key in cabinetDto) {
      formData.set(key, cabinetDto[key]);
    }

    uploadedPdf && formData.append('file', uploadedPdf);

    submit(formData);
  }

  useEffect(() => {
    if (submitError) {
      console.log(submitError);
      snackbar.errorMessage(submitError?.response?.data?.message ? submitError?.response?.data?.message : 'Ошибка')
    }
  }, [submitError]);

  useEffect(() => {
    if (submitResult) {
      snackbar.successMessage('Кабинет успешно добавлен.')
      updateCabinets();
      setOpen(false);
    }
  }, [submitResult])

  return (
    <Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>
        Подать заявку на новый кабинет
      </DialogTitle>
      <DialogContent>
        <TextField
          name={'address'}
          value={cabinetDto.address}
          onChange={handleAddress}
          label={'Адрес'}
          sx={{width: '100%', my: 2}}
        />
        <input
          ref={fileInputRef}
          type={'file'}
          onChange={handleFileEvent}
        />
        <LoadingButton
          variant={'contained'}
          onClick={() => {finishAppeal()}}
          loading={submitLoading}
        >
          Отправить заявку
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddCabinetModal;
