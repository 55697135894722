import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import { UserInfoChangeDto } from "./users.dto";

export const getAllUsers = () => {
    return axiosInstance.get(`${BACKEND_URL}/users`);
}

export const getSchoolUsers = () => {
    return axiosInstance.get(`${BACKEND_URL}/users/managers`);
}

export const getUserById = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/users/${userId}`);
}

export const getSchoolsByUserId = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schools/users/${userId}`);
}

export const changePassword = (userId: number, newPassword: string) => {
    return axiosInstance.post(`${BACKEND_URL}/users/change-password/${userId}`, {newPassword})
}

export const changeUserInfo = (dto: UserInfoChangeDto) => {
    return axiosInstance.post(`${BACKEND_URL}/users/change-userinfo`, dto)
}