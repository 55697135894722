import React, {useEffect, useState} from 'react';
import {Avatar, Box} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {useUserStore} from "../../global/store/UserStore";
import {getAdminJournal, getUserJournal} from "../actions/api";
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {getName} from "../../global/helpers/text";
import {wrapperSx} from "../../global/helpers/globalstyles";

const JournalPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const userInfo = useUserStore((state) => state);
    const [journal, setJournal] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (userInfo.isAdmin) {
            getAdminJournal()
                .then((res) => {
                    setJournal(res.data);
                    setLoading(false);
                })
        }

        if (!userInfo.isAdmin && userInfo.iin) {
            getUserJournal()
                .then((res) => {
                    setJournal(res.data);
                    setLoading(false);
                })
        }

        if (!userInfo.isAdmin && !userInfo.iin) {
            setLoading(false);
            snackbar.errorMessage('У вас нет доступа к данной странице. Необходимо авторизоваться');
            navigate('/auth');
        }
    // eslint-disable-next-line
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'timestamp',
            headerName: 'Дата, время',
            width: 300,
        },
        {
            field: 'type',
            headerName: 'Действие',
            width: 300,
            renderCell: ({row}) => {
                if (row.type === 'login') {
                    return 'Вход в систему'
                } else if (row.type === 'logout') {
                    return 'Выход из системы'
                }
            }
        },
        {
            field: 'user_id',
            headerName: 'ID пользователя',
            width: 300,
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            width: 300,
            renderCell: ({row}) => {
                const name = getName(row.user);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'ip',
            headerName: 'IP-адрес',
            width: 300,
        },
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <DataGrid
                    sx={{mt: 2}}
                    columns={columns}
                    rows={journal}
                    disableRowSelectionOnClick={true}
                    autoHeight={true}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    sortModel={[
                        {
                            field: 'id',
                            sort: 'desc'
                        }
                    ]}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default JournalPage;