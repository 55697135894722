import React, {FormEvent, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    Box,
    Button, Checkbox,
    CircularProgress, FormControl, FormControlLabel, FormHelperText,
    InputBase, Stack, TextField,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmployeesTable from "../components/EmployeesTable";
import {useSchoolStore} from "../../School/store/SchoolStore";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {EmployeeCreateDto} from "../actions/employee.type";
import {
    addDriverToSchool, addMasterToSchool,
    addTeacherToSchool,
    EmployeeType,
    getSchoolDrivers,
    getSchoolMasters,
    getSchoolTeachers
} from "../actions/api";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {convertFromDateTimeToDate} from "../../global/helpers/text";

interface EmployeesPageProps {
    employee: EmployeeType
}


const EmployeesPage = ({employee}: EmployeesPageProps) => {

    const {id} = useParams();
    const schoolInfo = useSchoolStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const [employeesInfo, setEmployeesInfo] = useState<any[]>([]);
    const [employeeForm, setEmployeeForm] = useState<EmployeeCreateDto>({
        first_name: '',
        last_name: '',
        patronymic: '',
        status: 1,
        school_id: schoolInfo.id,
        qualification_certificate: '',
        iin: '',
        driver_license_series: '',
        driver_license_number: '',
        driver_license_date_of_issue: '',
        driver_license_expires_date: '',
        driver_license_categories: [],
    });
    const [addLoading, setAddLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchEmployees();
    }, []);

    const employeeText = {
        teachers: {
            title: 'Преподаватели',
            subtitle: 'Здесь отображаются все преподаватели учебной организации ',
            button: 'Добавить преподавателя',
            newEmployee: 'Новый преподаватель'
        },
        drivers: {
            title: 'Мастера обучения вождению',
            subtitle: 'Здесь отображаются все мастера обучения вождению учебной организации',
            button: 'Добавить мастера',
            newEmployee: 'Новый мастер обучения вождению'
        },
        masters: {
            title: 'Мастера производственного обучения',
            subtitle: 'Здесь отображаются все мастера производственного обучения учебной организации',
            button: 'Добавить мастера',
            newEmployee: 'Новый мастер производственного обучения'
        }
    }

    const getEmployeesInfo = (id: any) => {
        if (employee === 'teachers') {
            return getSchoolTeachers(Number(id));
        }

        if (employee === 'drivers') {
            return getSchoolDrivers(Number(id));
        }

        if (employee === 'masters') {
            return getSchoolMasters(Number(id));
        }

        if (employee !== 'teachers' && employee !== 'drivers' && employee !== 'masters') {
            snackbar.errorMessage('Возникла проблема. Попробуйте перезайти на страницу сотрудников из страницы школы.')
        }

        return getSchoolTeachers(Number(id));
    }

    const fetchEmployees = () => {
        setLoading(true);

        getEmployeesInfo(Number(id))
            .then((res) => {
                console.log(`[EmployeesPage]:`)
                console.log(res);
                setEmployeesInfo(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })

    }

    const cleanInputs = () => {
        setEmployeeForm({
            first_name: '',
            last_name: '',
            patronymic: '',
            status: 1,
            school_id: schoolInfo.id,
            qualification_certificate: '',
            iin: '',
            driver_license_series: '',
            driver_license_number: '',
            driver_license_date_of_issue: '',
            driver_license_expires_date: '',
            driver_license_categories: [],
        })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmployeeForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAddLoading(true);

        if (employee === 'teachers') {
            addTeacherToSchool(employeeForm)
                .then((res) => {
                    console.log(res);
                    cleanInputs();
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setAddLoading(false);
                    fetchEmployees();
                })
        }

        if (employee === 'drivers') {
            addDriverToSchool(employeeForm)
                .then((res) => {
                    console.log(res);
                    cleanInputs();
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setAddLoading(false);
                    fetchEmployees();
                })
        }

        if (employee === 'masters') {
            addMasterToSchool(employeeForm)
                .then((res) => {
                    console.log(res);
                    cleanInputs();
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setAddLoading(false);
                    fetchEmployees();
                })
        }
    };

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = employeeForm.driver_license_categories.concat(name);

            setEmployeeForm((prev) => {
                return {
                    ...prev,
                    driver_license_categories: newCategories
                }
            })
        }

        if (!checked) {
            const isIncluded = employeeForm.driver_license_categories.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < employeeForm.driver_license_categories.length; i++) {
                    const element = employeeForm.driver_license_categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setEmployeeForm((prev) => {
                    return {
                        ...prev,
                        driver_license_categories: newCategories
                    }
                })
            }
        }
    }


    const pagesObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/schools',
            title: 'Список учебных организаций'
        },
        {
            path: `/schools/${schoolInfo.id}`,
            title: `${schoolInfo.title}`
        },
        {
            path: `/schools/${schoolInfo.id}/teachers`,
            title: `Преподаватели`
        },
    ]

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>{employeeText[employee].title}</b></Typography>
                        <Typography variant="subtitle2">
                            {employeeText[employee].subtitle} <i><b>{schoolInfo.title}</b></i>
                        </Typography>
                    </Stack>
                    {!dialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {setDialogOpen(true)}}
                            sx={{mt: '27px'}}
                        >
                            {employeeText[employee].button} <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {dialogOpen && <Box sx={{width: '773px', height: 'auto', p: '32px', background: '#fff', borderRadius: '16px', mt: '17px'}}>
                        <form style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}
                              onSubmit={handleSubmit}>
                            <p
                                onClick={() => {setDialogOpen(false)}}
                            >
                                <Typography
                                    variant={'h5'}
                                    sx={{
                                        mb: '14px',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    {employeeText[employee].newEmployee} <KeyboardArrowUpIcon style={{marginLeft: 'auto'}}/>
                                </Typography>
                            </p>
                            <InputBase
                                value={employeeForm.last_name}
                                onChange={handleInputChange}
                                name={'last_name'}
                                sx={{pl: 2}}
                                placeholder={'Фамилия'}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.first_name}
                                onChange={handleInputChange}
                                name={'first_name'}
                                sx={{pl: 2}}
                                placeholder={'Имя'}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.patronymic}
                                onChange={handleInputChange}
                                name={'patronymic'}
                                sx={{pl: 2}}
                                placeholder={'Отчество'}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.qualification_certificate}
                                placeholder={"Номер квалификационного свидетельства"}
                                name={'qualification_certificate'}
                                sx={{pl: 2}}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.iin}
                                placeholder={"ИИН"}
                                name={'iin'}
                                sx={{pl: 2}}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.driver_license_series}
                                placeholder={"Серия водительского удостоверения (2 буквы)"}
                                name={'driver_license_series'}
                                sx={{pl: 2}}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <InputBase
                                value={employeeForm.driver_license_number}
                                placeholder={"Номер водительского удостоверения (цифры)"}
                                name={'driver_license_number'}
                                sx={{pl: 2}}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <FormControl>
                                <InputBase
                                    value={employeeForm.driver_license_date_of_issue}
                                    name={'driver_license_date_of_issue'}
                                    onChange={handleInputChange}
                                    type={'date'}
                                    sx={{pl: 2}}
                                    required={true}
                                />
                                <FormHelperText>
                                    Дата выдачи ВУ
                                </FormHelperText>
                            </FormControl>

                            <FormControl>
                                <InputBase
                                    value={employeeForm.driver_license_expires_date}
                                    name={'driver_license_expires_date'}
                                    onChange={handleInputChange}
                                    type={'date'}
                                    sx={{pl: 2}}
                                    required={true}
                                />
                                <FormHelperText>
                                    Дата истечения ВУ
                                </FormHelperText>
                            </FormControl>
                            <Stack flexDirection={'column'}>
                                <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Категории водительского удостоверения:</b></Typography>
                                {addLoading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress /></Stack>}
                                {!addLoading && (<>
                                    <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('A1')} name={'A1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'A1'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('A')} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('B1')} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('B')} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('C1')} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('C')} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('D1')} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('D')} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('BE')} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('C1E')} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('CE')} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('DE')} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                        <FormControlLabel className={'category-label'} control={<Checkbox checked={employeeForm.driver_license_categories.includes('D1E')} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                                    </Box>
                                </>)}
                            </Stack>


                            {!addLoading && (
                                <Button
                                    variant={'contained'}
                                    type={'submit'}
                                    sx={{width: '227px', mt: '14px'}}
                                >
                                    Добавить
                                </Button>
                            )}
                            {addLoading && (<CircularProgress/>)}
                        </form>
                    </Box>}
                </Box>

                <EmployeesTable
                    rows={employeesInfo}
                    loading={loading}
                    fetchEmployees={fetchEmployees}
                    employee={employee}
                />
            </Box>
        </Box>
    );
};

export default EmployeesPage;