import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {getGroupById, startGroupApi} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {GroupDto} from "../actions/group.type";
import {useSchoolStore} from "../../School/store/SchoolStore";
import {GroupHeader} from "../components/GroupPage/GroupHeader";
import GroupMainPage from "../components/GroupPage/GroupMainPage";
import AddStudentModal from "../components/GroupPage/AddStudentModal";
import StudentsGrid from "../components/GroupPage/StudentsGrid";
import ExamsGrid from "../components/GroupPage/ExamsGrid";
import PageHeader from "../../global/components/PageHeader";
import TeachersWindow from "../components/GroupPage/TeachersWindow";
import DriversWindow from "../components/GroupPage/DriversWindow";
import TransportWindow from "../components/GroupPage/TransportWindow";
import MastersWindow from "../components/GroupPage/MasterWindow";
import {wrapperSx} from "../../global/helpers/globalstyles";
import ClassesGrid from '../components/GroupPage/ClassesGrid';

const GroupPage = () => {

    const params = useParams();
    const navigate = useNavigate();
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [selectedTab, setSelectedTab] = useState<string>('main');
    const [groupInfo, setGroupInfo] = useState<GroupDto>({
        id: Number(params.id),
        school_id: schoolInfo.id ? schoolInfo.id : 999999,
        category_id: 'A',
        status: 1,
        title: '',
        students: [],
        start_date: null,
        end_date: null,
        teacher: null,
        driver: null,
        master: null,
        transport: null
    });
    const [groupInfoLoading, setGroupInfoLoading] = useState<boolean>(false);

    // Modal
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        fetchGroupInfo();
    }, [])

    const tabs = {
        main: {id: 'main', label: 'Обзор'},
        students: {id: 'students', label: `Курсанты (${groupInfo.students.length})`},
        teachers: {id: 'teachers', label: `Преподаватель (${groupInfo.teacher ? '1' : '0'})`},
        driver: {id: 'drivers', label: `Мастер обучения вождению (${groupInfo.driver ? '1' : '0'})`},
        master: {id: 'masters', label: `Мастер произв. обучения (${groupInfo.master ? '1' : '0'})`},
        transport: {id: 'transport', label: `Транспорт (${groupInfo.transport ? groupInfo.transport.length : '0'})`},
        classes: {id: 'classes', label: `Занятия`},
        exams: {id: 'exams', label: `Экзамены`},
        // logs: {id: 'logs', label: `История действий`}
    };

    const fetchGroupInfo = async () => {
        setGroupInfoLoading(true);
        try {
            const res = await getGroupById(Number(params.id));
            console.log(`GROUPINFO`)
            console.log(res.data);
            setGroupInfo(res.data);
            setGroupInfoLoading(false);
        } catch (err: any) {
            snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка');
            navigate('/groups');
        }
    };

    const formGroup = () => {

        const hasMaster = groupInfo.driver || groupInfo.master;

        if (groupInfo.students.length < 1 || groupInfo.students.length > 30 || !groupInfo.teacher || !hasMaster || !groupInfo.transport) {
            snackbar.errorMessage('Необходимо сформировать группу минимум из 1 курсанта, максимум из 30 курсантов, преподавателя, мастера обучения вождению/мастера производственного обучения. А также прикрепить учебный транспорт.');
            return;
        }

        startGroupApi(groupInfo.id)
            .then((res) => {
                snackbar.successMessage('Статус группы успешно сменен на "Идет обучение"');
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <GroupHeader
                    groupInfo={groupInfo}
                />
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    variant="scrollable"
                    scrollButtons={true}
                    sx={{mb: 2}}
                >
                    {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
                </Tabs>

                {selectedTab === 'main' && (
                    <GroupMainPage
                        groupInfo={groupInfo}
                        addStudent={() => {setOpenModal(true)}}
                        formGroup={formGroup}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                )}

                {selectedTab === 'students' && (
                    <StudentsGrid
                        rows={groupInfo.students}
                        fetchGroupInfo={fetchGroupInfo}
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'teachers' && (
                    <TeachersWindow
                        groupInfo={groupInfo}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                )}

                {selectedTab === 'drivers' && (
                    <DriversWindow
                        groupInfo={groupInfo}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                )}

                {selectedTab === 'masters' && (
                    <MastersWindow
                        groupInfo={groupInfo}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                )}

                {selectedTab === 'transport' && (
                    <TransportWindow
                        groupInfo={groupInfo}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                )}

                {selectedTab === 'classes' && (
                  <ClassesGrid
                    groupInfo={groupInfo}
                    fetchGroupInfo={fetchGroupInfo}
                  />
                )}

                {selectedTab === 'exams' && (
                    <ExamsGrid
                        rows={groupInfo.students}
                        fetchGroupInfo={fetchGroupInfo}
                        groupInfo={groupInfo}
                    />
                )}

                <AddStudentModal
                    groupInfo={groupInfo}
                    open={openModal}
                    onClose={() => {setOpenModal(false)}}
                    fetchGroupInfo={fetchGroupInfo}
                />

                <Dialog
                    maxWidth={'xl'}
                    fullWidth={true}
                    open={groupInfoLoading}
                >
                    <DialogTitle>
                        Загрузка данных из АИС Автошкола
                    </DialogTitle>
                    <DialogContent>
                        <Stack flexDirection={'column'} gap={2}>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <Typography>Обновляются данные учебной группы</Typography>
                            </Stack>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <CircularProgress />
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default GroupPage;