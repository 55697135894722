import React, {FormEvent, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {Box, Button, InputBase, Stack, TextField, Typography} from "@mui/material";
import {TicketCreateDto} from "../actions/tickets.dto";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {createTicket} from "../actions/api";
import {useNavigate} from "react-router-dom";
import {wrapperSx} from "../../global/helpers/globalstyles";

const NewTicketPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const [formData, setFormdata] = useState<TicketCreateDto>({
        title: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormdata((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formData.title || !formData.message) {
            snackbar.errorMessage('Необходимо заполнить оба поля.')
        } else {
            createTicket(formData)
                .then((res) => {
                    navigate('/tickets');
                    snackbar.successMessage('Обращение успешно подано');
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
        }
    }

    const pageObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/tickets',
            title: 'Служба поддержки'
        },
        {
            path: '/tickets/new',
            title: 'Новое обращение'
        }
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Новое обращение</b></Typography>
                    <Typography variant="subtitle2">
                        Оставьте обращение, связанное с проблемами или вопросами по системе. Наша техническая поддержка ответит Вам в течение рабочего дня.
                    </Typography>
                </Stack>

                <Box className={'appeals'} sx={{p: '16px', background: '#fff', borderRadius: '16px', mt: 2}}>
                    <form
                        onSubmit={handleSubmit}
                        style={{display: 'flex', flexDirection: 'column', gap: '20px', margin: '10px'}}
                    >
                        <InputBase
                            name={'title'}
                            placeholder={'Тема обращения'}
                            value={formData.title}
                            onChange={handleChange}
                        />
                        <InputBase
                            multiline={true}
                            rows={12}
                            name={'message'}
                            placeholder={'Текст обращения'}
                            value={formData.message}
                            onChange={handleChange}
                        />
                        <Button
                            type={'submit'}
                            variant={'contained'}
                        >
                            Отправить
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default NewTicketPage;