import React, {useState} from 'react';
import {EmployeeType, fireDriver, fireMaster, fireTeacher} from "../actions/api";
import {Box, Button, CircularProgress} from "@mui/material";


interface EmployeesActionProps {
    row: any,
    fetchEmployees: () => void,
    employee: EmployeeType
}

const EmployeesAction = ({row, fetchEmployees, employee}: EmployeesActionProps) => {

    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeStatus = (id: number) => {
        setLoading(true);

        if (employee === 'teachers') {
            fireTeacher(id)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                    fetchEmployees();
                })
        }

        if (employee === 'drivers') {
            fireDriver(id)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                    fetchEmployees();
                })
        }

        if (employee === 'masters') {
            fireMaster(id)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                    fetchEmployees();
                })
        }

    }

    if (row.status === 1) {
        return (
            <Box>
                {loading && (<CircularProgress />)}
                {!loading && (<Button
                    variant={'outlined'}
                    color={'info'}
                    sx={{height: 'auto'}}
                    onClick={() => {handleChangeStatus(row.id)}}
                >
                    Перенести в архив
                </Button>)}
            </Box>
        )
    } else {
        return (
            <Box>
                {'-'}
            </Box>
        )
    }
}

export default EmployeesAction;