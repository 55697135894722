import { Avatar, Box, Button, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '../../global/helpers/globalstyles';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import {createScheduleByEmployeeId, getEmployeeWithSchedule} from '../actions/api';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import EmployeeCard from '../components/EmployeeCard';

interface EmployeeDto {
    last_name: string,
    first_name: string
}

const StandaloneSchedule = () => {

    const {id, employeeId} = useParams();
    const snackbar = useSnackbarStore((state) => state);
    const [scheduleInfo, scheduleLoading, , fetchSchedule] = useLoadingHook(getEmployeeWithSchedule);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [selectedHours, setSelectedHours] = useState<string[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [tempDays, setTempDays] = useState<string[]>([]);
    const [tempHours, setTempHours] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [employeeInfo, setEmployeeInfo] = useState<EmployeeDto>({
        last_name: '',
        first_name: ''
    })


    const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const daysLabels = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
    const hoursRange = Array.from({ length: 15 }, (_, i) => (i + 7).toString().padStart(2, '0') + ":00");

    useEffect(() => {
        fetchSchedule(Number(employeeId));
    }, [])

    useEffect(() => {
        if (scheduleInfo) {
            setSelectedDays(scheduleInfo.days);
            setSelectedHours(scheduleInfo.hours);
            scheduleInfo.employee.type === 'driver' ? setEmployeeInfo({
                last_name: scheduleInfo.employee.driver.last_name,
                first_name: scheduleInfo.employee.driver.first_name,
            }) : setEmployeeInfo({
                last_name: scheduleInfo.employee.master.last_name,
                first_name: scheduleInfo.employee.master.first_name,
            })
        }
    }, [scheduleInfo])

    useEffect(() => {
        if (editMode) {
        setTempDays(selectedDays);
        setTempHours(selectedHours);
        }
    }, [editMode]);

    const toggleDay = (day: string) => {
        if (tempDays.includes(day)) {
        setTempDays(prev => prev.filter(d => d !== day));
        } else {
        setTempDays(prev => [...prev, day]);
        }
    };

    const toggleHour = (hour: string) => {
        if (tempHours.includes(hour)) {
        setTempHours(prev => prev.filter(h => h !== hour));
        } else {
        setTempHours(prev => [...prev, hour]);
        }
    };

    const saveChanges = () => {
        createScheduleByEmployeeId({
            days: tempDays,
            hours: tempHours,
            employee_id: scheduleInfo.employee.id
        })
            .then((res) => {
                snackbar.successMessage(`Расписание успешно обновлено для сотрудника ${employeeInfo.last_name} ${employeeInfo.first_name}`);
                fetchSchedule(Number(employeeId));
            })
            .catch((err) => {
                console.log(err);
            })
        setSelectedDays(tempDays);
        setSelectedHours(tempHours);
        setEditMode(false);
    };

    const cancelChanges = () => {
        setTempDays(selectedDays);
        setTempHours(selectedHours);
        setEditMode(false);
    };

    if (scheduleLoading) {
        return (
            <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader />
                <Box sx={{...wrapperSx}}>
                    <CircularProgress />
                </Box>
            </Box>
        )
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Управление расписанием сотрудника</b></Typography>
                    </Stack>
                </Box>

                <EmployeeCard employeeInfo={employeeInfo} />

                <Stack gap={2} sx={{mt: 2}}>
                    <Stack direction="row" spacing={1}>
                        {daysOfWeek.map((day, index) => (
                            <Chip
                                key={day}
                                label={daysLabels[index]}
                                clickable={editMode}
                                onClick={editMode ? () => toggleDay(day) : undefined}
                                color={editMode ? (tempDays.includes(day) ? 'primary' : 'default') : (selectedDays.includes(day) ? 'primary' : 'default')}
                            />
                        ))}
                    </Stack>

                    <Grid container spacing={1}>
                        {hoursRange.map(hour => (
                        <Grid item key={hour}>
                            <Chip
                                key={hour}
                                label={hour}
                                clickable={editMode}
                                onClick={editMode ? () => toggleHour(hour) : undefined}
                                color={editMode ? (tempHours.includes(hour) ? 'primary' : 'default') : (selectedHours.includes(hour) ? 'primary' : 'default')}
                            />
                        </Grid>
                        ))}
                    </Grid>

                    {editMode ? (
                        <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="primary" onClick={saveChanges}>
                            Сохранить изменения
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={cancelChanges}>
                            Отменить изменения
                        </Button>
                        </Stack>
                    ) : (
                        <Button variant="outlined" color="primary" onClick={() => setEditMode(true)}>
                        Изменить расписание
                        </Button>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

export default StandaloneSchedule