import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getExamsProtocol} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";

const GroupEnrollment = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const [pdfData, setPdfData] = useState<string>('');

    useEffect(() => {
        fetchInfo()
    }, [id])

    const fetchInfo = () => {
        getExamsProtocol(Number(id))
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });

                const url = URL.createObjectURL(blob);
                setPdfData(url);
            })
            .catch((err) => {
                snackbar.errorMessage(`Нет доступа к данному протоколу`)
                navigate('/')
            })
    }

    return (
        <iframe
            src={pdfData}
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: '0',
                top: '0'
            }}
        >
        </iframe>
    );
};

export default GroupEnrollment;