import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';

interface EmployeeDto {
    last_name: string,
    first_name: string
}

interface EmployeeCardProps {
    employeeInfo: EmployeeDto
}

const EmployeeCard = ({employeeInfo}: EmployeeCardProps) => {
  return (
    <Stack 
        direction={'row'} 
        alignItems={'center'} 
        sx={{p: 2, background: '#fff', borderRadius: '16px', width: 'fit-content'}}
    >
        <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
        />
        <Typography variant={'body2'}>{employeeInfo.last_name} {employeeInfo.first_name}</Typography>
    </Stack>
  )
}

export default EmployeeCard