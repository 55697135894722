import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {Avatar, Box, Button, CircularProgress, Stack, Tooltip, Typography} from "@mui/material";
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {
    attachEmployeeToDriving,
    getActiveSchoolDrivers,
    getActiveSchoolMasters,
    getDrivingEmployees
} from "../actions/api";
import {useNavigate, useParams} from "react-router-dom";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useSchoolStore} from "../../School/store/SchoolStore";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {formatDate, getName} from "../../global/helpers/text";
import EmployeesAction from "../../Employees/components/EmployeesAction";
import NoRows from "../../global/components/NoRows";

interface ButtonProps {
    row: any
}

const DrivingEmployees = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);
    const [driversList, driversLoading, , fetchDrivers] = useLoadingHook(getActiveSchoolDrivers);
    const [mastersList, mastersLoading, , fetchMasters] = useLoadingHook(getActiveSchoolMasters);
    const [employeesList, employeesLoading, , fetchEmployees] = useLoadingHook(getDrivingEmployees);
    const [addedDrivers, setAddedDrivers] = useState<any[]>([]);
    const [addedMasters, setAddedMasters] = useState<any[]>([]);

    useEffect(() => {
        fetchInfo();
    }, []);

    useEffect(() => {
        if (employeesList) {
            const newAddedDrivers: any[] = [];
            employeesList.map((employee: any) => {
                if (employee.type === 'driver') {
                    newAddedDrivers.push(employee.driver.id);
                }
            });
            setAddedDrivers(newAddedDrivers);

            const newAddedMasters: any[] = [];
            employeesList.map((employee: any) => {
                if (employee.type === 'master') {
                    newAddedMasters.push(employee.master.id);
                }
            });
            setAddedMasters(newAddedMasters);
        }
    }, [employeesList])

    const fetchInfo = () => {
        fetchDrivers(Number(id));
        fetchMasters(Number(id));
        fetchEmployees({
            school_id: Number(id),
            product_id: 1
        })
    }

    const AddDriverToDriving = ({row}: ButtonProps) => {
        const [loading, setLoading] = useState<boolean>(false);

        const attachEmployee = () => {
            setLoading(true);
            attachEmployeeToDriving({
                employee_id: row.id,
                school_id: Number(id),
                region_id: 1,
                employee_type: 'driver'
            })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка')
                })
                .finally(() => {
                    setLoading(false);
                    fetchInfo();
                })
        }

        if (loading) {
            return <CircularProgress />
        }

        return (
            <Button
                sx={{height: 'auto'}}
                variant={'contained'}
                onClick={() => {attachEmployee()}}
                disabled={addedDrivers.includes(Number(row.id))}
            >
                Добавить в список
            </Button>
        )
    };

    const AddMasterToDriving = ({row}: ButtonProps) => {
        const [loading, setLoading] = useState<boolean>(false);

        const attachEmployee = () => {
            setLoading(true);
            attachEmployeeToDriving({
                employee_id: row.id,
                school_id: Number(id),
                region_id: 1,
                employee_type: 'master'
            })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка')
                })
                .finally(() => {
                    setLoading(false);
                    fetchInfo();
                })
        }

        if (loading) {
            return <CircularProgress />
        }

        return (
            <Button
                sx={{height: 'auto'}}
                variant={'contained'}
                onClick={() => {attachEmployee()}}
                disabled={addedMasters.includes(Number(row.id))}
            >
                Добавить в список
            </Button>
        )
    }

    const DeleteEmployeeFromDriving = ({row}: ButtonProps) => {
        const [loading, setLoading] = useState<boolean>(false);

        if (loading) {
            return <CircularProgress />
        }

        return (
            <Tooltip title={'В разработке'}>
                <Box>
                    <Button sx={{height: 'auto'}} variant={'contained'} disabled={true}>
                        Удалить из списка
                    </Button>
                </Box>
            </Tooltip>
        )
    }

    const columnsForDrivers: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 360, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {field: 'job', headerName: 'Должность', width: 200, renderCell: (() => 'Мастер обучения вождению')},
        {field: 'actions', headerName: 'Действия', minWidth: 200, renderCell: ({row}) => <AddDriverToDriving row={row}/>}
    ];

    const columnsForMasters: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 360, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {field: 'job', headerName: 'Должность', width: 200, renderCell: (() => 'Мастер производственного обучения')},
        {field: 'actions', headerName: 'Действия', minWidth: 200, renderCell: ({row}) => <AddMasterToDriving row={row}/>}
    ];

    const columnsForDriving: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 360, sortable: true,
            renderCell: ({row}) => {
                const employeeInfo = row.type === 'driver' ? {
                    ...row.driver
                } : {
                    ...row.master
                };

                const name = getName(employeeInfo);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {field: 'actions', headerName: 'Действия', minWidth: 200, renderCell: ({row}) => <DeleteEmployeeFromDriving row={row}/>}
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Сотрудники, доступные для записи на вождение</b></Typography>
                    </Stack>
                </Box>

                <Stack flexDirection={'row'} gap={1} sx={{mt: 2}}>
                    <Stack sx={{width: '50%'}} flexDirection={'column'} alignItems={'center'} gap={1}>
                        <Typography variant={'subtitle1'}>
                            Мастера обучения вождению
                        </Typography>
                        <DataGrid
                            columns={columnsForDrivers}
                            rows={driversList ? driversList : []}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            loading={driversLoading}
                            autoHeight={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            style={{margin: '0', height: '100%', width: '100%'}}
                            slots={{
                                noRowsOverlay: NoRows
                            }}
                        />
                    </Stack>
                    <Stack sx={{width: '50%'}} flexDirection={'column'} alignItems={'center'} gap={1}>
                        <Typography variant={'subtitle1'}>
                            Мастера производственного обучения
                        </Typography>
                        <DataGrid
                            columns={columnsForMasters}
                            rows={mastersList ? mastersList : []}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            loading={mastersLoading}
                            autoHeight={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            style={{margin: '0', height: '100%', width: '100%'}}
                            slots={{
                                noRowsOverlay: NoRows
                            }}
                        />
                    </Stack>
                </Stack>

                <Stack sx={{mt: 2}}>
                    <Stack sx={{width: '100%'}} flexDirection={'column'} alignItems={'center'} gap={1}>
                        <Typography variant={'subtitle1'}>
                            Список сотрудников для записи на вождение
                        </Typography>
                        <DataGrid
                            columns={columnsForDriving}
                            rows={employeesList ? employeesList : []}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            loading={employeesLoading}
                            autoHeight={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            style={{margin: '0', height: '100%', width: '100%'}}
                            slots={{
                                noRowsOverlay: NoRows
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default DrivingEmployees;