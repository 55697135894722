import {BACKEND_URL} from '../../global/actions/apiVariables';
import axiosInstance from "../../global/actions/main.api";
import {TestQuestionsFilterDto, TestQuestionsSearchDto} from "./testQuestionsSearch.type";
import {TestPassCategory} from "./testPass.type";

export const getCategories = () => {
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/categories`);
}

export const getQuestionsSearch = (query: TestQuestionsFilterDto) => {
    let queryString = !query.category_id ? `?count=${query.count}&offset=${query.offset}&sort=${query.sort}` :
                                            `?count=${query.count}&offset=${query.offset}&sort=${query.sort}&category_id=${query.category_id}`
    let queryString2 = !query.q ? `${queryString}` :
                                        `${queryString}&q=${query.q}`
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/search${queryString2}`);
}

export const getQuestionById = (id: number | null | undefined) => {
    console.log('ID    ' + id)
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/${id}`);
}
export const createQuestion = (questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions`, questionData);
}
export const updateQuestion = (questionId: string | number, questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/update/${questionId}`, questionData);
}
export const reportQuestion = (questionId: string | number, questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/report/${questionId}`, questionData);
}

export const deleteQuestionById = (id: number | null | undefined) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/delete/${id}`);
}
export const updateQuestionCategory = (questionId: number, categoryId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/${questionId}/category/${categoryId}`);
}
export const createTest = (categoryId: TestPassCategory, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/new/${categoryId}`, body);
}
export const getTestPassById = (passId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/tests/pass/${passId}`);
}
export const answerQuestion = (passId: number, answerId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/pass/${passId}/answer/${answerId}`);
}
// export const finishTest = (passId: number) => {
//     return axiosInstance.post(`/tests/pass/${passId}/finish`);
// }

// Метод запуска тестирования (таймера)
export const startTimer = (testPassId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/start/${testPassId}`, body);
}

// Метод получения оставшегося времени
export const getTimer = (testPassId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/tests/test/timer/${testPassId}`);
}

// Метод получения вопросов
export const getQuestionsArray = (testPassId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/questions/${testPassId}`, body);
}

// Метод получения истории ответов
export const getAnswersHistory = (testPassId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/answers/${testPassId}`, body);
}

// Метод получения данных вопроса
export const getQuestionData = (questionId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/get-question/${questionId}`, body);
}

// Метод внесения ответа
export const submitAnswer = (testPassId: number, questionId: number, answerId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/submit-answer/${testPassId}`, {...body, questionId, answerId});
}

// Метод окончания тестирования
export const finishTest = (testPassId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/finish/${testPassId}`, body);
}

export const getTestResults = (testPassId: number, body: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/test/result/${testPassId}`, body);
}
