import {CircularProgress, IconButton, Paper} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import {green, red} from '@mui/material/colors';
import {Accept, useDropzone} from 'react-dropzone';
import {useEffect, useMemo, useState} from 'react';
import snackbar from "./snackbar";
import {getMediaUploadStatus, uploadMediaFile} from "../../media/actions/api";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    margin: '5px 0',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: '.15s ease-in-out',
    transitionProperty: 'border, backgroundColor',
    cursor: 'pointer'
};

const focusedStyle = {
    backgroundColor: '#e5f4ff',
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function CircularProgressWithLabel(props: any) {
    const styles = {height: 35, width: 35, marginRight: 20, marginLeft: 10};
    return (
        <>
            {props.value === -1 && <ErrorIcon style={{...styles, color: red[500]}}/>}
            {props.value === 100 && <DoneIcon style={{...styles, color: green[500]}}/>}
            {!props.value && <CircularProgress style={styles} variant={'indeterminate'} {...props} />}
            {props.value && props.value >= 0 && props.value < 100 &&
                <CircularProgress style={styles} variant={'determinate'} {...props} />}
        </>
    );
}

type LoadingStatus = 'new' | 'uploading' | 'subscribe' | 'processing' | 'error' | 'finish'

interface Props {
    sizeLimitMb: number;
    accept: Accept;
    description: string;
    onUpload: (data: any) => void;
}

export default function DropZone({sizeLimitMb, accept, description, onUpload}: Props) {
    const [fileData, setFileData] = useState<File | null>(null);
    const [status, setStatus] = useState<LoadingStatus | null>(null);
    const [progress, setProgress] = useState<number | null>(null);
    const [id, setId] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [intervalId, setIntervalId] = useState<any | null>(null);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
        acceptedFiles,
        fileRejections
    } = useDropzone({accept});
    console.log(fileData?.type);
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused || isDragActive ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused, isDragAccept, isDragReject, isDragActive
    ]);

    const statuses: { [key in LoadingStatus]: string } = {
        'new': 'Подготовка файла',
        'uploading': 'Загрузка',
        'subscribe': 'Загрузка',
        'processing': 'Обработка',
        'finish': 'Обработка завершена!',
        'error': 'Произошла ошибка при обработке!'
    };

    useEffect(() => {
        const file = acceptedFiles[0];

        if (file) {
            console.log('Attached File', file);
            if (file.size / 1024 / 1024 > sizeLimitMb) {
                setError(`Размер файла превышает ${sizeLimitMb} МБ`);
            } else {
                setFileData(file);
                setStatus('new');
                setProgress(null);
                setError(null);
            }
            return;
        }

        if (fileRejections.length > 0) setError('Не удалось прикрепить файл');
    }, [fileRejections, acceptedFiles]);

    useEffect(() => {
        async function uploadFile(fileData: File) {
            let uploadFile: File = fileData;
            await uploadMediaFile(uploadFile, (i) => setProgress(i === 100 ? 90 : i))
                .then(r => {
                    setId(r.data.id);
                    setStatus('finish');
                })
                .catch(err => {
                    console.log(err);
                    setErrorMessage(err?.response?.data?.message || 'Произошла ошибка: ' + err);
                    setStatus('error');
                });
        }

        if (status === 'new') {
            const formData = new FormData();
            if (!fileData) return;
            formData.append('file', fileData);
            setStatus('processing');
            uploadFile(fileData)


        } else if (status === 'finish') {
            const interval = setInterval(async () => {
                await getMediaUploadStatus(`${id}.mp4`)
                    .then(r => {
                        setProgress(100);
                        try {
                            setTimeout(() => onUpload(r.data), 1500);
                        } catch (e) {

                        }
                        clearInterval(interval);
                    })
                    .catch(err => {
                        console.log('Axios Error', err);
                        setStatus('error');
                        clearInterval(interval);
                    });
            }, 1000);

            setIntervalId(interval);
        }
    }, [status]);

    const deleteFile = () => {
        setFileData(null);
        setStatus(null);
        setProgress(null);
        setId(null);
        setError(null);
        setErrorMessage(null);
        clearInterval(intervalId);
        setIntervalId(null);
    };

    return (
        <section className="container">
            {fileData &&
                <>
                    <Paper elevation={2}
                           style={{
                               margin: 10,
                               padding: 15,
                               display: 'flex',
                               alignItems: 'center',
                               width: '85%',
                           }}>
                        <CircularProgressWithLabel value={progress}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p style={{margin: 3}}>
                                {/* @ts-ignore */}
                                <b>{fileData?.path}</b> ({(fileData.size / 1024 / 1024).toFixed(2)} МБ)
                            </p>
                            <p style={{margin: 3}}>{status && statuses[status]} {progress && progress >= 0 && progress + '%'}</p>
                        </div>
                        <IconButton color="primary" aria-label="upload picture" component="span"
                                    style={{marginLeft: 'auto', color: red[700]}} onClick={deleteFile}>
                            <CloseIcon/>
                        </IconButton>
                    </Paper>
                    {errorMessage && <p style={{color: red[500], width: '85%', margin: 10}}>{errorMessage}</p>}
                </>
            }
            {!fileData && <>
                {/* @ts-ignore */}
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>Перетащите файл или нажмите, чтобы выбрать</p>
                    {description && <em>{description}</em>}
                </div>
                {error && <p style={{color: red[500]}}>{error}</p>}
            </>}
        </section>
    );
}