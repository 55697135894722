import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from '@mui/material';
import React from 'react';

interface TestBeginningDialogProps {
    open: boolean,
    onClose: () => void,
}

const TestBeginningDialog = ({open, onClose} : TestBeginningDialogProps) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Пробный экзамен
            </DialogTitle>
            <DialogContent>
                <Stack sx={{gap: 2}}>
                    <Typography>
                        Добро пожаловать на пробный теоретический экзамен на управление транспортным средством.
                    </Typography>
                    <Typography>
                        Обращаем ваше внимание на то, что данный экзамен повторяет все условия существующего, проводимого в СпецЦОНах Республики Казахстан.
                        Время проведения - 40 минут.
                    </Typography>
                    <Typography>
                        Допустимое количество ошибок - 8.
                    </Typography>
                    <Typography>
                        Вы можете переключаться между вопросами и изменять уже внесенные ответы.
                    </Typography>
                    <Typography>
                        Для завершения теста необходимо нажать на кнопку "Завершить тестирование" на последнем вопросе.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'contained'}
                    onClick={onClose}
                >
                    Приступить к тестированию
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TestBeginningDialog;