import React, {useEffect, useState} from 'react';
import {Box, Button, InputBase, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {registerInputStyle} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import {IRegisterUser} from "../../Auth/dto/AuthDto";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {registerNewWorker} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";

interface RegisterWorkerDialogProps {
    schoolId: number
    fetchWorkers: (schoolId: number) => void,
    onClose: () => void
}

const RegisterWorkerDialog = ({schoolId, fetchWorkers, onClose}: RegisterWorkerDialogProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [userForm, setUserForm] = useState<IRegisterUser>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    });
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);
    const [registerResponse, registerLoading, registerError, executeRegistration] = useLoadingHook(registerNewWorker)


    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const handleWorkerSubmit = () => {
        if (!userForm.iin || !userForm.last_name || !userForm.first_name || !userForm.email || !userForm.phone) {
            snackbar.errorMessage(`Остались пустые необходимые поля. Введите значения.`)
            return;
        }

        if (userForm.password !== confirmPassword) {
            snackbar.errorMessage(`Введенные пароли не совпадают. Проверьте их значения.`)
            return;
        }
        executeRegistration({
            user_dto: userForm,
            school_id: Number(schoolId)
        })
    };

    useEffect(() => {
        if (registerResponse) {
            cleanForm();
            fetchWorkers(schoolId);
        }
    }, [registerResponse])

    useEffect(() => {
        if (registerError) {
            console.log(registerError)
            snackbar.errorMessage(registerError.response.data.message ? registerError.response.data.message : 'Произошла ошибка')
        }
    }, [registerError])

    const cleanForm = () => {
        setConfirmPassword('');
        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        })
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '773px',
                height: 'auto',
                p: '32px',
                background: '#fff',
                borderRadius: '16px',
                mt: '17px',
            }}
            flexDirection={'column'}
            gap={1}
        >
            <Button
                variant={'contained'}
                onClick={onClose}
                // onClick={() => {snackbar.infoMessage('Данный функционал на данный момент разрабатывается.')}}
                sx={{mb: 1}}
            >
                Скрыть окно <KeyboardArrowUpIcon style={{marginLeft: '10px'}}/>
            </Button>
            <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Регистрация нового специалиста</b></Typography>

            <InputBase
                type={'text'}
                placeholder={'ИИН*'}
                value={userForm.iin}
                name={'iin'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <InputBase
                type={'text'}
                placeholder={'Фамилия*'}
                value={userForm.last_name}
                name={'last_name'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <InputBase
                type={'text'}
                placeholder={'Имя*'}
                value={userForm.first_name}
                name={'first_name'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <InputBase
                type={'text'}
                placeholder={'Отчество'}
                value={userForm.patronymic}
                name={'patronymic'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={false}
                disabled={registerLoading}
            />

            <InputBase
                type={'text'}
                placeholder={'Электронная почта*'}
                value={userForm.email}
                name={'email'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <InputBase
                type={'text'}
                placeholder={'Номер телефона*'}
                value={userForm.phone}
                name={'phone'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Пароль</b></Typography>

            <InputBase
                type={hidden ? 'password' : 'text'}
                placeholder={'Придумайте пароль'}
                value={userForm.password}
                name={'password'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            <InputBase
                type={hidden ? 'password' : 'text'}
                placeholder={'Подтвердите пароль'}
                value={confirmPassword}
                name={'confirmPassword'}
                onChange={(e) => setConfirmPassword(e.target.value)}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={registerLoading}
            />

            {hidden && <Button
                variant={'outlined'}
                color={'info'}
                onClick={() => {setHidden(false)}}
            >
                Показать пароль
            </Button>}
            {!hidden && <Button
                variant={'outlined'}
                color={'info'}
                onClick={() => {setHidden(true)}}
            >
                Скрыть пароль
            </Button>}
            <LoadingButton
                variant={'outlined'}
                color={'primary'}
                onClick={handleWorkerSubmit}
                loading={registerLoading}
            >
                Зарегистрировать
            </LoadingButton>
        </Box>
    );
};

export default RegisterWorkerDialog;