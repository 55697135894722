import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";

export const getCreatedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/created`);
}

export const getApprovedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/approved`);
}

export const getClosedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/closed`);
}


export const getDeniedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/denied`);
}

export const getAppealById = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/${id}`);
}

export const approveAppealApi = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/approve/${id}`, {});
}

export const deleteAppeal = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/delete/${id}`, {})
}

export const denyAppeal = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/deny/${id}`, {});
}

export const resendEmail = (email: any) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/resend`, email);
}

export const changeEmailByAdmin = (email: string, appealId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/change-email/${appealId}`, {email});
}