import React, { useState, useEffect } from 'react';
import { Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TimerProps {
  seconds: number;
  onTimerComplete: () => void;
}

const TestTimer: React.FC<TimerProps> = ({ seconds, onTimerComplete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [remainingSeconds, setRemainingSeconds] = useState<number>(seconds);

  useEffect(() => {
    setRemainingSeconds(remainingSeconds);
  }, [seconds])

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (remainingSeconds > 0) {
      interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval!); // Используем '!' для указания на то, что переменная не равна null или undefined
      onTimerComplete();
    }

    return () => clearInterval(interval);
  }, [remainingSeconds, onTimerComplete]);

  const minutes: number = Math.floor(remainingSeconds / 60);
  const formattedMinutes: string = minutes < 10 ? `0${minutes}` : minutes.toString();
  const formattedSeconds: string =
    remainingSeconds % 60 < 10 ? `0${remainingSeconds % 60}` : (remainingSeconds % 60).toString();

  return (
    <div>
      <Typography variant={isMobile ? 'subtitle1' : 'h5'} gutterBottom>
        {formattedMinutes}:{formattedSeconds}
      </Typography>
    </div>
  );
};

export default TestTimer;