import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    Paper,
    Skeleton,
    Stack,
    SxProps,
    Theme,
    Typography, useTheme
} from '@mui/material';
import React from 'react';
import { TestPassCategory } from '../actions/testPass.type';
import {TestCategoryMainPageItem} from "../actions/Test.type";
import useMediaQuery from '@mui/material/useMediaQuery';

interface TestsListProps {
    tests: Array<TestCategoryMainPageItem>,
    loadingCategory: TestPassCategory | null,
    openDialog: (category: number, categoryTitle: string) => void,
    sxProps: any
}

const paperSx: SxProps<Theme> = {
    padding: 2,
    boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.30) 0px 5px 12px -4px',
    mb: 2,
    borderRadius: 4
};


export const testButtonSx: SxProps<Theme> = {
    // background: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    p: 2,
    mb: 2,
    borderRadius: 5,
    cursor: 'pointer',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    userSelect: 'none',
    transitionProperty: 'box-shadow, transform',
    transition: '0.2s ease',
    '& .test_button_icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(151.09deg, #569BF5 6.29%, #4688E8 96.44%, #366AB7 96.44%)',
        borderRadius: '12px',
        mr: 2,
        width: 46,
        height: 46
    },
    '& .test_button_loading': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.54)',
        borderRadius: 5,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    '&:hover': {
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px',
        transform: 'scale(1.01)'
    },
    '&:active': {
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 5px 18px -4px',
        transform: 'scale(1.00)'
    }
};

export function TestsList({tests, loadingCategory, openDialog, sxProps}: TestsListProps) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getCategoryImage = (categoryId: number) => {
        if (categoryId === 1) {
            return 'url(/images/A.png)'
        } else if (categoryId === 2) {
            return 'url(/images/B.png)'
        } else if (categoryId === 3) {
            return 'url(/images/C.png)'
        } else if (categoryId === 8) {
            return 'url(/images/BC.png)'
        } else if (categoryId === 5) {
            return 'url(/images/D.png)'
        } else if (categoryId === 6) {
            return 'url(/images/TM.png)'
        } else if (categoryId === 4) {
            return 'url(/images/D.png)'
        }
    }

    return (
        <Stack
          flexDirection={'row'}
          justifyContent={'center'}
          gap={3}
          sx={{p: 2, flexWrap: 'wrap'}}
        >
            {tests.map((test) =>
                <Paper
                    key={test.category_id}
                    variant="elevation"
                    onClick={() => {
                        if (!loadingCategory) {
                            openDialog(test.category_id, test.title);
                        }
                    }}
                    sx={{
                        ...testButtonSx,
                        position: 'relative',
                        width: isMobile ? '200px' : '300px',
                        height: isMobile ? '200px' : '300px',

                        // Псевдоэлемент для фонового изображения
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: getCategoryImage(test.category_id),
                            backgroundSize: 'cover', // Это гарантирует, что изображение будет покрывать весь элемент
                            backgroundPosition: 'center',
                            opacity: 0.1, // Установите желаемую прозрачность
                        },

                        // Стили для содержимого, чтобы оно отображалось поверх фонового изображения
                        '& > *': {
                            position: 'relative',
                        },
                    }}
                >
                    {loadingCategory === test.category_id && <Box className="test_button_loading">
                        <CircularProgress/>
                    </Box>}
                    <Box>
                        <Typography fontWeight={600} fontSize={17} sx={{mb: 0}}>
                            {test.title}
                        </Typography>
                        <Typography fontWeight={400} fontSize={15} sx={{mt: 0}}>
                            {test.subtitle}
                        </Typography>
                    </Box>
                </Paper>
            )}
        </Stack>
    );
}
