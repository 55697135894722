import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getRegions } from '../../Auth/actions/api';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { getAllEmployeesFromRegion } from '../actions/api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getName } from '../../global/helpers/text';
import NoRows from '../../global/components/NoRows';
import AppointmentDateTime from './AppointmentDateTime';
import {InfoOutlined} from "@mui/icons-material";

interface KursantMakeAppointmentProps {
  iin: string,
  last_name: string,
  onClose: () => void,
  open: boolean,
  fetchKursantInfo: () => void
}

const KursantMakeAppointment = ({ iin, last_name, onClose, open, fetchKursantInfo} : KursantMakeAppointmentProps) => {
  const [regionsList, regionsLoading, , fetchRegions] = useLoadingHook(getRegions);
  const [employeesList, employeesLoading, , fetchEmployees] = useLoadingHook(getAllEmployeesFromRegion);
  const [region, setRegion] = useState<number | string | null>('');
  const [employee, setEmployee] = useState<number | null>(null);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (region) {
      console.log(region);
      fetchEmployees(region);
      setEmployee(null);
    }
  }, [region]);

  const handleRegionChange = (event: any) => {
    const selectedRegion = event.target.value;
    setRegion(selectedRegion);
  };

  const columnsForDriving: GridColDef[] = [
    {field: 'fio', headerName: 'ФИО', minWidth: 360,
        renderCell: ({row}) => {
            const employeeInfo = row.type === 'driver' ? {
                ...row.driver
            } : {
                ...row.master
            };

            const name = getName(employeeInfo);
            return <React.Fragment>
                <Avatar
                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                >
                    {name.partialName}
                </Avatar>
                {name.fullName}
            </React.Fragment>;
        }
    },
    {
      field: 'school', 
      headerName: 'Автошкола', minWidth: 360,
      renderCell: ({row}) => row.school.title
    },
    {
      field: 'region',
      headerName: 'Регион',
      width: 200,
      renderCell: ({row}) => row.school.region
    },
    {
      field: 'locality',
      headerName: 'Населенный пункт',
      width: 200,
      renderCell: ({row}) => row.school.locality
    }
  ];

  return (
    <Dialog 
      open={open} 
      maxWidth={'xl'}
    >
      <DialogTitle>Запись на вождение</DialogTitle>
      <DialogContent>
        <Stack gap={2}>

          <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 1}}>
            <InfoOutlined sx={{color: 'gray', mr: 2}}/>
            <Stack>
              <Typography variant={'subtitle2'}><b>Внимание!</b> Необходимо выбрать регион для отображения списка доступных инструкторов</Typography>
            </Stack>
          </Stack>

          <Select
            value={region}
            onChange={handleRegionChange}
            placeholder='Выберите регион'
            fullWidth
            variant="outlined"
          >
            {regionsList?.map((r: any) => (
              <MenuItem key={r.id} value={r.id}>
                {r.name_ru}
              </MenuItem>
            ))}
          </Select>

          {region && (
            <DataGrid
              columns={columnsForDriving}
              rows={employeesList ? employeesList : []}
              initialState={{
                  pagination: {
                      paginationModel: {
                          pageSize: 20,
                      },
                  },
              }}
              loading={employeesLoading}
              autoHeight={true}
              pageSizeOptions={[20]}
              disableRowSelectionOnClick={true}
              style={{margin: '0', height: '100%', width: '100%'}}
              slots={{
                  noRowsOverlay: NoRows
              }}
              onRowClick={({row}) => setEmployee(row.id)}
            />
          )}

          {employee && (
            <Box>
              <AppointmentDateTime
                  employeeId={employee}
                  iin={iin}
                  last_name={last_name}
                  fetchKursantInfo={fetchKursantInfo}
                  mainDialogClose={onClose}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='error'>
          Закрыть окно
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default KursantMakeAppointment