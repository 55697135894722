import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {LoadingButton, LoadingButtonProps} from "@mui/lab";

const Letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

interface Props {
    i: number;
    label: string;
    disabled?: boolean;
    correct?: boolean;
    incorrect?: boolean;
}

const CustomButton = styled(LoadingButton)<LoadingButtonProps & Pick<Props, 'correct' | 'incorrect'>>(({
                                                                                                           theme,
                                                                                                           correct,
                                                                                                           incorrect
                                                                                                       }) => ({
    padding: 0,
    borderRadius: 18,
    marginTop: 9,
    marginBottom: 9,
    maxWidth: 500,
    minWidth: 250,
    height: 'auto',
    // border: correct ? '1px solid #2bb352' : incorrect ? '1px solid #b32b2b' : '1px solid #2b53b3',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    transitionDuration: '.3s',
    textAlign: 'left',
    textTransform: 'none',
    transitionProperty: 'background-color, transform, color',
    // boxShadow: '0px 2px 25px rgba(63, 143, 254, 0.15)',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        transform: 'scale(1.03)'
    },
    '& .text': {
        paddingLeft: 19,
        paddingRight: 18,
        paddingTop: 22,
        paddingBottom: 22,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.25,

    },
    '& .Mui-disabled': {
        color: correct || incorrect ? '#FFFFFF !important' : '#000000 !important',
        opacity: 1
    },
    '& .MuiLoadingButton-loadingIndicator': {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            width: '30px !important',
            height: '30px !important',
            color: '#2196f3'
        }
    }
}));

export default function AnswerButton({i, label, correct, incorrect, ...props}: Props & LoadingButtonProps) {
    const theme = useTheme();
    return <CustomButton sx={{
        backgroundColor: correct ? '#1BA875' : incorrect ? '#CF5035' : (theme.palette.mode === 'light') ? '#FFFFFF' : '#152336',
        '&:hover': {
            backgroundColor: correct ? '#1BA875' : incorrect ? '#CF5035' : (theme.palette.mode === 'light') ? '#E1E9F4' : '#152336'
        },
        '& .text': {
            color: correct || incorrect ? '#FFFFFF !important' : theme.palette.mode === 'light' ? '#000000 !important' : '#FFFFFF !important'
        }
    }} correct={correct} incorrect={incorrect} {...props}>
        <Box className="text"><b>{Letters[i]}.</b> {label}</Box>
    </CustomButton>;
}
