import { BACKEND_URL } from '../../global/actions/apiVariables';
import axiosInstance from "../../global/actions/main.api";
import {AttachNewSchoolDto, CategoriesChangeDto} from "./category.type";
import {TransactionStartDto} from "../pages/SchoolTopUpPage";
import {WorkerAttachDto, WorkerCreateDto} from "./users.dto";
import { DocsDto } from '../pages/SchoolKursantPage';

export const getUserSchools = () => {
    return axiosInstance.get(`${BACKEND_URL}/schools`);
}

export const getUsersBySchoolId = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/users/workers/${schoolId}`);
}

export const getSchoolInfo = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schools/${id}`);
}

export const getSchoolAttributes = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schools/attributes/count/${id}`)
}

export const getSchoolCategories = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/categories/${id}`);
}

export const changeSchoolCategories = (dto: CategoriesChangeDto) => {
    return axiosInstance.post(`${BACKEND_URL}/categories/change`, dto);
}

export const deleteSchoolCascade = (schoolId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/school/delete/${schoolId}`, {})
}

export const deleteSchoolDima = (schoolId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/school/delete-only-school/${schoolId}`, {})
}

export const attachNewSchoolToUser = (data: AttachNewSchoolDto) => {
    return axiosInstance.post(`${BACKEND_URL}/schools/new-school`, data);
}

export const updateSchoolByAdmin = (data: any) => {
    return axiosInstance.post(`${BACKEND_URL}/schools/update-school`, data);
}

export const getSchoolBalance = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/balance/${schoolId}`)
}

export const getSchoolTransactions = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/${schoolId}`)
}

export const submitTopUp = (transactionDto: TransactionStartDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transactions/start`, transactionDto)
}

// Добавление нового специалиста в школу
export const registerNewWorker = (dto: WorkerCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/users/worker/create`, dto);
}

export const attachNewWorker = (dto: WorkerAttachDto) => {
    return axiosInstance.post(`${BACKEND_URL}/users/worker/attach`, dto)
}

// Получить список курсантов
export const getKursantsBySchoolId = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/students/school/${schoolId}`);
}

// Получить информацию о курсанте
export const getKursantInfo = (kursantId: number, schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/students/studentInfo/${kursantId}?schoolId=${schoolId}`);
}

// Внести дополнительную информацию о курсанте
export const submitKursantDocsInfo = (kursantId: number, dto: DocsDto) => {
    return axiosInstance.post(`${BACKEND_URL}/students/doc/save/${kursantId}`, dto)
}

// Методы для получения документов по курсанту
export const getExamCard = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/exam/${kursantId}`, { responseType: 'arraybuffer' })
}

export const getKursantContract = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/contract/${kursantId}`, { responseType: 'arraybuffer' })
}

export const getStudyingConfirmation = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/confirmation/${kursantId}`, { responseType: 'arraybuffer' })
}

export const getExamResults = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/results/${kursantId}`, { responseType: 'arraybuffer' })
}


