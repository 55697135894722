import {Avatar, Box, Chip, Tab, Tabs} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PageHeader from '../../global/components/PageHeader'
import { wrapperSx } from '../../global/helpers/globalstyles'
import PageInsideHeader from '../../global/components/PageInsideHeader'
import useLoadingHook from '../../global/hooks/UseLoadingHook'
import { Link, useParams } from 'react-router-dom'
import { useUserStore } from '../../global/store/UserStore'
import { useSnackbarStore } from '../../global/store/SnackbarStore'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { getName } from '../../global/helpers/text'
import NoRows from '../../global/components/NoRows'
import { getCorrectionsBySchoolId } from '../actions/api'

const SchoolCorrections = () => {

    const {id} = useParams();
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [correctionsList, correctionsLoading, , fetchCorrections] = useLoadingHook(getCorrectionsBySchoolId);
    const [selectedTab, setSelectedTab] = useState<string>('false');

    useEffect(() => {
        fetchCorrections(Number(id), selectedTab);
    }, []);

    useEffect(() => {
        fetchCorrections(Number(id), selectedTab)
    }, [selectedTab])

    const tabs = {
        created: {id: 'false', label: `Новые`},
        approved: {id: 'true', label: `Подтвержденные`},
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            sortable: true,
        },
        {
            field: 'previous_fio', headerName: 'Предыдущее ФИО', width: 300, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {row.previous_last_name} {row.previous_first_name} {row.previous_patronymic}
                </React.Fragment>;
            }
        },
        {
            field: 'new_fio', headerName: 'Новое ФИО', minWidth: 300, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {row.new_last_name} {row.new_first_name} {row.new_patronymic}
                </React.Fragment>;
            }
        },
        {
            field: 'previous_iin',
            headerName: 'Предыдущий ИИН',
            width: 175,
            sortable: false,
        },
        {
            field: 'new_iin',
            headerName: 'Новый ИИН',
            width: 175,
            sortable: false,
        },
        {
            field: 'group.title',
            headerName: 'Группа',
            minWidth: 100,
            sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link
                        to={`/groups/${row.group.id}`}
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {row.group.title}
                    </Link>
                </React.Fragment>;
            }
        },
        {
            field: 'done', headerName: 'Статус заявки', minWidth: 300, sortable: false,
            renderCell: ({row}) => {
                return row.done ? (<Chip label={'Исполнено'} color={'primary'}/>) : (<Chip label={'В ожидании'}/>)
            }
        },
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <PageInsideHeader
                    text={'Корректировки'}
                    subtext={'На данной странице вы можете просмотреть историю корректировок вашей учебной организации'}
                />

            <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>
            <DataGrid
                columns={columns}
                rows={correctionsList ? correctionsList : []}
                loading={correctionsLoading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
            </Box>
        </Box>
    )
}

export default SchoolCorrections