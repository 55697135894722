import { BACKEND_URL } from "../../global/actions/apiVariables";
import axiosInstance from "../../global/actions/main.api";
import {NotificationCreateDto, NotificationMarkDto} from "./notifications.dto";

export const createNotification = (dto: NotificationCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/notifications/create`, dto);
}

export const getAllNotifications = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/notifications/all/${userId}`);
}

export const getNewNotifications = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/notifications/unmarked/${userId}`);
}

export const markNotification = (dto: NotificationMarkDto) => {
    return axiosInstance.post(`${BACKEND_URL}/notifications/mark`, dto);
}
