import {Box} from '@mui/material';
import React from 'react';
import PageHeader from "../../global/components/PageHeader";
import SchoolsListComponent from '../components/SchoolsListComponent';
import {wrapperSx} from "../../global/helpers/globalstyles";

const SchoolsList = () => {

    const pagesObj = [
        {
            path: '/',
            title: 'Главная'
        }
    ]

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <SchoolsListComponent />
            </Box>
        </Box>
    );
};

export default SchoolsList;
