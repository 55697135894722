import React from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Avatar, Box, Chip, Typography} from "@mui/material";
import {formatDate, formatDateTime, getName} from "../../global/helpers/text";
import NoRows from '../../global/components/NoRows';

interface KursantDrivingGridProps {
    rows: any[],
    loading: boolean
}

export enum TestPassCategory {
    '-' = 0,
    'A' = 1,
    'B' = 2,
    'C' = 3,
    'D' = 4,
    'CD' = 5,
    'TM' = 6
}
export enum AppointmentStatus {
    'Не использован' = 0,
    'Идет прохождение' = 1,
    'Сдан' = 2,
    'Провален' = 3,
    'Завершен' = 4,
    'Завершен несдачей' = 5
}

const KursantPromocodeGrid = ({rows, loading} : KursantDrivingGridProps) => {
    console.log(rows)

    const columns: GridColDef[] = [
        {
            field: 'code', headerName: 'Промокод',
            width: 150,
            renderCell: ({row}) => `${row.code}`
        },
        {
            field: 'buyer_iin', headerName: 'ИИН покупателя',
            width: 200,
            renderCell: ({row}) => `${row.buyer_iin}`
        },
        {
            field: 'buyer_last_name', headerName: 'Фамилия покупателя',
            width: 200,
            renderCell: ({row}) => row.buyer_last_name
        },
        {
            field: 'buyer_iin', headerName: 'ИИН тестируемого',
            width: 200,
            renderCell: ({row}) => row.testee_iin
        },
        {
            field: 'testee_last_name', headerName: 'Фамилия тестируемого',
            width: 200,
            renderCell: ({row}) => row.testee_last_name
        },
        {
            field: 'category', headerName: 'Категория теста',
            width: 200,
            renderCell: ({row}) => {
                return <Chip label={TestPassCategory[row.pass ? row.pass.category : 0]}/>
            }
        },
        {
            field: 'correct_count', headerName: 'Правильных ответов',
            width: 200,
            renderCell: ({row}) => row.pass ? row.pass.correct_count : 0
        },
        {
            field: 'incorrect_count', headerName: 'Неправильных ответов',
            width: 200,
            renderCell: ({row}) => row.pass ? row.pass.incorrect_count : 0
        },
        {
            field: 'status', headerName: 'Статус записи',
            width: 200,
            renderCell: ({row}) => {
                return <Chip label={AppointmentStatus[row.used ? row.pass.status : 0]}/>
            }
        },
    ];

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                loading={loading}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default KursantPromocodeGrid;