import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import React, { FormEvent, useEffect, useState } from 'react'
import PageHeader from '../../global/components/PageHeader'
import { wrapperSx } from '../../global/helpers/globalstyles'
import { NotificationCreateDto } from '../actions/notifications.dto'
import useLoadingHook from '../../global/hooks/UseLoadingHook'
import { createNotification } from '../actions/notifications.api'
import LoadingButton from '../../global/components/LoadingButton'

export const AdminCreateNotification = () => {

    const [ , createLoading, , create] = useLoadingHook(createNotification);
    const [notificationDto, setNotificationDto] = useState<NotificationCreateDto>({
        title: '',
        text: '',
        type: '',
        target: null
    });

    const cleanForm = () => {
        setNotificationDto({
            title: '',
            text: '',
            type: '',
            target: ''
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNotificationDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleTypeSelect = (e: SelectChangeEvent<any>) => {
        setNotificationDto((prev) => {
            return {
                ...prev,
                type: e.target.value
            }
        })
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        create(notificationDto);
    };

    useEffect(() => {
        if (!createLoading) {
            cleanForm();
        }
    }, [createLoading])

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Stack sx={{mb: 2}} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Создать новое уведомление</b></Typography>
                </Stack>

                <form
                    onSubmit={handleSubmit}
                >
                    <Stack gap={1}>
                        <TextField 
                            value={notificationDto.title}
                            name={'title'}
                            helperText={'Название'}
                            onChange={handleChange}
                            required={true}
                        />
                        <TextField 
                            value={notificationDto.text}
                            name={'text'}
                            helperText={'Текст'}
                            onChange={handleChange}
                            required={true}
                            multiline={true}
                            minRows={5}
                            inputProps={{style: {borderRadius: '0', height: 'auto'}}}
                        />
                        <FormControl>
                            <Select
                                value={notificationDto.type}
                                required={true}
                                onChange={handleTypeSelect}
                            >
                                <MenuItem value={'general'}>
                                    Все пользователи
                                </MenuItem>
                                {/* <MenuItem value={'role'}>
                                    Специалисты автошкол
                                </MenuItem> */}
                                {/* <MenuItem value={'personal'}>
                                    Определенный пользователь
                                </MenuItem> */}
                            </Select>
                            <FormHelperText id={'region-label'}>Тип уведомления</FormHelperText>
                        </FormControl>

                        <LoadingButton 
                            type='submit'
                            variant='contained'
                            loading={createLoading}
                        >
                            Создать
                        </LoadingButton>
                    </Stack>
                </form>
            </Box>
        </Box>
    )
}
