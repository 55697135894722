import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import PageInsideHeader from "../../global/components/PageInsideHeader";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getEmployeeInfo, isEmployeeType, updateEmployeeInfo} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {InfoOutlined} from "@mui/icons-material";
import {EmployeeInfo} from "../actions/employee.type";
import LoadingButton from "../../global/components/LoadingButton";
import {convertFromDateTimeToDate} from "../../global/helpers/text";

const EmployeeEditInfo = () => {

    const {id, employeeId} = useParams();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const [employeeInfo, employeeLoading, employeeError, fetchEmployeeInfo] = useLoadingHook(getEmployeeInfo);
    const [newEmployeeInfo, setNewEmployeeInfo] = useState<EmployeeInfo>({
        first_name: '',
        last_name: '',
        patronymic: '',
        qualification_certificate: '',
        iin: '',
        driver_license_series: '',
        driver_license_number: '',
        driver_license_date_of_issue: '',
        driver_license_expires_date: '',
        driver_license_categories: [],
    });
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [submit, submitLoading, submitError, submitChanges] = useLoadingHook(updateEmployeeInfo);

    useEffect(() => {
        if (!isEmployeeType(type)) {
            snackbar.errorMessage(`Некорректная ссылка на страницу. Попробуйте перезайти из таблицы соответствующих сотрудников.`)
            navigate('/');
        } else {
            fetchEmployeeInfo(Number(employeeId), Number(id), type);
        }
    }, []);

    useEffect(() => {
        if (employeeInfo) {
            setNewEmployeeInfo(() => {
                return {
                    first_name: employeeInfo.first_name,
                    last_name: employeeInfo.last_name,
                    patronymic: employeeInfo.patronymic,
                    qualification_certificate: ternaryEmptyString(employeeInfo.qualification_certificate),
                    iin: ternaryEmptyString(employeeInfo.iin),
                    driver_license_series: ternaryEmptyString(employeeInfo.driver_license_series),
                    driver_license_number: ternaryEmptyString(employeeInfo.driver_license_number),
                    driver_license_date_of_issue: ternaryEmptyString(employeeInfo.driver_license_date_of_issue),
                    driver_license_expires_date: ternaryEmptyString(employeeInfo.driver_license_expires_date),
                    driver_license_categories: employeeInfo.driver_license_categories ? employeeInfo.driver_license_categories : [],
                }
            })
        }
    }, [employeeInfo]);

    const ternaryEmptyString = (arg: any) => {
        if (!arg) {
            return '';
        } else {
            return arg;
        }
    }

    useEffect(() => {
        if (employeeError) {
            snackbar.errorMessage(employeeError ? employeeError : 'Произошла ошибка');
            navigate('/');
        }
    }, [employeeError]);

    useEffect(() => {
        if (submit) {
            setIsChanging(false);
            fetchEmployeeInfo(Number(employeeId), Number(id), type);
        }
    }, [submit]);

    useEffect(() => {
        if (submitError) {
            setIsChanging(false);
            snackbar.errorMessage(submitError ? submitError : 'Произошла ошибка при сохранении новых данных. Попробуйте еще раз.');
        }
    }, [submitError]);

    if (!employeeInfo || employeeLoading) {
        return (
            <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader />
                <Box sx={{...wrapperSx}}>
                    <PageInsideHeader text={"Корректировка информации по сотруднику"} />
                    <CircularProgress />
                </Box>
            </Box>
        )
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!isChanging) {
            snackbar.errorMessage(`Вы не можете редактировать данное поле не в режиме изменения.`)
            return;
        } else {
            setNewEmployeeInfo((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    };

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = newEmployeeInfo.driver_license_categories.concat(name);

            setNewEmployeeInfo((prev) => {
                return {
                    ...prev,
                    driver_license_categories: newCategories
                }
            })
        }

        if (!checked) {
            const isIncluded = newEmployeeInfo.driver_license_categories.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < newEmployeeInfo.driver_license_categories.length; i++) {
                    const element = newEmployeeInfo.driver_license_categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setNewEmployeeInfo((prev) => {
                    return {
                        ...prev,
                        driver_license_categories: newCategories
                    }
                })
            }
        }
    }

    const handleSubmit = () => {
        const dto = {
            id: employeeId,
            ...newEmployeeInfo
        };
        submitChanges(dto, Number(id), type);
    };

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx, pb: 3}}>
                <PageInsideHeader text={"Корректировка информации по сотруднику"} />
                <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 2}}>
                    <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                    <Stack>
                        <Typography variant={'subtitle2'}><b>Внимание!</b> С обновлением системы от 01.11.2023 г. необходимо внести следующую информацию по сотруднику:</Typography>
                        <Typography variant={'subtitle2'}>ИИН, номер квалификационного свидетельства, данные по водительскому удостоверению.</Typography>
                    </Stack>
                </Stack>

                <Stack flexDirection={'column'} gap={3}>
                    <Stack flexDirection={'column'} gap={2}>
                        <TextField
                            value={isChanging ? newEmployeeInfo.last_name : employeeInfo?.last_name}
                            label={"Фамилия"}
                            name={'last_name'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.first_name : employeeInfo?.first_name}
                            label={"Имя"}
                            name={'first_name'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.patronymic : employeeInfo?.patronymic ? employeeInfo.patronymic : ''}
                            label={"Отчество"}
                            name={'patronymic'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.qualification_certificate : employeeInfo?.qualification_certificate ? employeeInfo?.qualification_certificate : ''}
                            label={"Номер квалификационного свидетельства"}
                            name={'qualification_certificate'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.iin : employeeInfo?.iin ? employeeInfo.iin : ''}
                            label={"ИИН"}
                            name={'iin'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.driver_license_series : employeeInfo?.driver_license_series ? employeeInfo.driver_license_series : ''}
                            label={"Серия водительского удостоверения (2 буквы)"}
                            name={'driver_license_series'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? newEmployeeInfo.driver_license_number : employeeInfo?.driver_license_number ? employeeInfo.driver_license_number : ''}
                            label={"Номер водительского удостоверения (цифры)"}
                            name={'driver_license_number'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={isChanging ? convertFromDateTimeToDate(newEmployeeInfo.driver_license_date_of_issue) : employeeInfo?.driver_license_date_of_issue ? convertFromDateTimeToDate(employeeInfo.driver_license_date_of_issue) : ''}
                            name={'driver_license_date_of_issue'}
                            disabled={!isChanging}
                            onChange={handleChange}
                            type={'date'}
                            helperText={"Дата выдачи ВУ"}
                        />
                        <TextField
                            value={isChanging ? convertFromDateTimeToDate(newEmployeeInfo.driver_license_expires_date) : employeeInfo?.driver_license_expires_date ? convertFromDateTimeToDate(employeeInfo.driver_license_expires_date) : ''}
                            name={'driver_license_expires_date'}
                            disabled={!isChanging}
                            onChange={handleChange}
                            type={'date'}
                            helperText={"Дата истечения ВУ"}
                        />
                        <Stack flexDirection={'column'}>
                            <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Категории водительского удостоверения:</b></Typography>
                            {employeeLoading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress /></Stack>}
                            {!employeeLoading && (<>
                                <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('A1') : employeeInfo?.driver_license_categories.includes('A1')} disabled={!isChanging} name={'A1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'A1'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('A') : employeeInfo?.driver_license_categories.includes('A')} disabled={!isChanging} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('B1') : employeeInfo?.driver_license_categories.includes('B1')} disabled={!isChanging} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('B') : employeeInfo?.driver_license_categories.includes('B')} disabled={!isChanging} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('C1') : employeeInfo?.driver_license_categories.includes('C1')} disabled={!isChanging} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('C') : employeeInfo?.driver_license_categories.includes('C')} disabled={!isChanging} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('D1') : employeeInfo?.driver_license_categories.includes('D1')} disabled={!isChanging} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('D') : employeeInfo?.driver_license_categories.includes('D')} disabled={!isChanging} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('BE') : employeeInfo?.driver_license_categories.includes('BE')} disabled={!isChanging} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('C1E') : employeeInfo?.driver_license_categories.includes('C1E')} disabled={!isChanging} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('CE') : employeeInfo?.driver_license_categories.includes('CE')} disabled={!isChanging} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('DE') : employeeInfo?.driver_license_categories.includes('DE')} disabled={!isChanging} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                    <FormControlLabel className={'category-label'} control={<Checkbox checked={isChanging ? newEmployeeInfo?.driver_license_categories.includes('D1E') : employeeInfo?.driver_license_categories.includes('D1E')} disabled={!isChanging} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                                </Box>
                            </>)}
                        </Stack>
                    </Stack>
                    <Stack flexDirection={'row'} gap={3}>
                        {!isChanging && (
                            <Button
                                onClick={() => {
                                    setIsChanging(true)
                                }}
                                variant={'contained'}
                            >
                                Изменить информацию
                            </Button>
                        )}
                        {isChanging && (
                            <LoadingButton
                                onClick={() => {
                                    setIsChanging(false)
                                }}
                                variant={'contained'}
                                color={'error'}
                                loading={submitLoading}
                            >
                                Отменить изменения
                            </LoadingButton>
                        )}
                        {isChanging && (
                            <LoadingButton
                                onClick={() => {
                                    handleSubmit()
                                }}
                                variant={'contained'}
                                loading={submitLoading}
                            >
                                Сохранить изменения
                            </LoadingButton>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default EmployeeEditInfo;